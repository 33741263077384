import axios from "@/axios";

export default {
  state: {
    profiles: [],
    currentProfile: {},
  },
  mutations: {
    updateProfiles(state, data) {
      data.sort((a, b) => {
        return a.name > b.name;
      });

      state.profiles = data;
    },

    clearProfiles(state) {
      state.profiles = [];
      state.currentProfile = {};
    },

    setCurrentProfile(state, data) {
      if (data.competences) {
        data.competences.sort((a, b) => a.indexNumber - b.indexNumber);
        data.competences.forEach(c =>
          c.indicators.sort((a, b) => a.indexNumber - b.indexNumber)
        );
      }
      state.currentProfile = data;
    },

    pushProfile(state, data) {
      state.profiles.push(data);

      state.profiles.sort((a, b) => {
        return a.name > b.name;
      });
    },
    spliceProfileCopy(state, { id, copy }) {
      const index = state.profiles.findIndex(el => el.id == id);

      state.profiles.splice(index, 0, copy);
    },

    removeProfile(state, id) {
      state.profiles = state.profiles.filter(el => el.id != id);
    },

    setProfileName(state, { id, name, description, sessionDurationMinutes }) {
      const profile = state.profiles.find(el => el.id == id);
      profile.name = name;
      profile.description = description;
      profile.sessionDurationMinutes = sessionDurationMinutes;

      state.currentProfile.name = name;
      state.currentProfile.description = description;
      state.currentProfile.sessionDurationMinutes = sessionDurationMinutes;
    },

    setProfilePositions(state, { currentPositions, targetPositions }) {
      state.currentProfile.currentPositions = currentPositions;
      state.currentProfile.targetPositions = targetPositions;
    },

    createCompetence(state, competence) {
      state.currentProfile.competences.push(competence);
    },

    updateCompetence(state, competence) {
      const index = state.currentProfile.competences.findIndex(
        el => el.id == competence.id
      );
      state.currentProfile.competences.splice(index, 1, competence);
    },

    removeCompetence: (state, id) =>
      (state.currentProfile.competences = state.currentProfile.competences
        .filter(el => el.id !== id)
        .map((el, index) => ({ ...el, indexNumber: index + 1 }))),

    updateRatingScale(state, ratingScale) {
      state.currentProfile.ratingScale = ratingScale;
    },

    updateReportSettings(state, reportSettings) {
      const reportIndex = state.currentProfile.reportSettings.findIndex(
        el => el.role == reportSettings.role
      );

      state.currentProfile.reportSettings.splice(
        reportIndex,
        1,
        reportSettings
      );
    },

    setProfileSimulation(state, data) {
      Object.keys(data).forEach(key => {
        state.currentProfile.simulation[key] = data[key];
      });
    },

    removeProfileDoc(state, docId) {
      state.currentProfile.simulation.docs =
        state.currentProfile.simulation.docs.filter(el => el.id != docId);
    },

    createProfileDoc(state, doc) {
      state.currentProfile.simulation.docs.push(doc);
    },

    updateProfileDoc(state, doc) {
      const index = state.currentProfile.simulation.docs.findIndex(
        el => el.id == doc.id
      );
      state.currentProfile.simulation.docs.splice(index, 1, doc);
    },

    updateSimulationDateTime(state, { date, time }) {
      state.currentProfile.simulation.simulationDate = date;
      state.currentProfile.simulation.simulationTime = time;
    },

    createProfileEmail(state, email) {
      state.currentProfile.simulation.emails.push(email);
    },

    updateProfileEmail(state, email) {
      const index = state.currentProfile.simulation.emails.findIndex(
        el => el.id == email.id
      );

      state.currentProfile.simulation.emails.splice(index, 1, email);
    },

    removeProfileEmail(state, emailId) {
      state.currentProfile.simulation.emails =
        state.currentProfile.simulation.emails.filter(el => el.id != emailId);
    },

    updateProfileEmails(state, emails) {
      state.currentProfile.simulation.emails = emails;
    },

    createProfileEvent(state, event) {
      state.currentProfile.simulation.events.push(event);
    },

    updateProfileEvent(state, event) {
      const index = state.currentProfile.simulation.events.findIndex(
        el => el.id == event.id
      );

      state.currentProfile.simulation.events.splice(index, 1, event);
    },

    removeProfileEvent(state, eventId) {
      state.currentProfile.simulation.events =
        state.currentProfile.simulation.events.filter(el => el.id != eventId);
    },

    updateProfileEvents(state, events) {
      state.currentProfile.simulation.events = events;
    },

    pushMaterial(state, data) {
      state.currentProfile.materials.push(data);
    },

    updateMaterial(state, data) {
      const material =
        state.currentProfile.materials[
          state.currentProfile.materials.findIndex(el => el.id == data.id)
        ];

      material.name = data.name;
      material.link = data.link;
    },

    removeMaterial(state, id) {
      state.currentProfile.materials = state.currentProfile.materials.filter(
        el => el.id != id
      );
    },
    pushQuestionnaire(state, data) {
      delete data["id"];
      state.currentProfile.questionnaire = data;
    },
  },
  actions: {
    async fetchProfiles({ commit, getters }) {
      if (getters.getProfiles.length) return;

      await axios.get("/api/v1/admin/profiles").then(res => {
        if (!res.error) {
          commit("updateProfiles", res.data);
        } else {
          return res.error;
        }
      });
    },

    async fetchProfileById({ commit, dispatch }, id) {
      if (!id) return;

      await axios.get("/api/v1/admin/profile?id=" + id).then(res => {
        if (!res.error) {
          res.simulation = {
            simulationDate: "",
            simulationTime: "",
            emails: [],
            events: [],
            docs: [],
          };
          commit("setCurrentProfile", res);
        } else {
          return res.error;
        }
      });

      await dispatch("fetchRecipients", id);
    },

    async createProfile({ commit, dispatch }, data) {
      return await axios
        .post(`/api/v1/admin/profile`, data, { successText: "Профиль создан" })
        .then(async res => {
          if (!res.error) {
            await dispatch("fetchProfileById", res.id);
            commit("pushProfile", res);
          }
          return res.error;
        });
    },

    async removeProfile({ commit }, id) {
      return await axios
        .delete("/api/v1/admin/profile", {
          params: {
            id: id,
          },
          successText: "Профиль удален",
        })
        .then(res => {
          if (!res.error) {
            commit("removeProfile", id);
          } else {
            return res.error;
          }
        });
    },

    async updateProfileName({ commit }, data) {
      return await axios
        .put(
          "/api/v1/admin/profile",
          {
            name: data.name,
            description: data.description,
            sessionDurationMinutes: data.sessionDurationMinutes,
          },
          {
            params: {
              id: data.id,
            },
            successText: "Профиль сохранен",
          }
        )
        .then(res => {
          if (!res.error) {
            commit("setProfileName", data);
          }
          return res.error;
        });
    },

    async updateProfilesPositions({ commit }, { profileId, positions }) {
      return await axios
        .post(
          "/api/v1/admin/admin/positions",
          JSON.stringify(
            {
              currentPositionIds: positions.currentPositions.map(el => el.id),
              targetPositionIds: positions.targetPositions.map(el => el.id),
            },
            { successText: "Должности обновлены" }
          ),
          {
            params: {
              profileId: profileId,
            },
          }
        )
        .then(res => {
          if (!res.error) {
            commit("setProfilePositions", positions);
          }
          return res.error;
        });
    },

    removeCompetence: ({ commit }, { profileId, competenceId }) =>
      axios
        .delete("/api/v1/admin/competence", {
          params: {
            profileId: profileId,
            competenceId: competenceId,
          },
          successText: "Компетенция удалена",
        })
        .then(() => commit("removeCompetence", competenceId)),

    async copyProfile({ commit }, id) {
      return await axios
        .post(
          `/api/v1/admin/profile/copy?profileId=${id}`,
          {},
          {
            successText: "Профиль скопирован",
            timeout: 5 * 60 * 1000,
          }
        )
        .then(res => {
          if (!res.error) {
            commit("spliceProfileCopy", {
              id: id,
              copy: res,
            });
          }
          return res.error;
        });
    },

    async updateCompetence({ commit }, { profileId, competence }) {
      if (competence.id) {
        const updatedCompetence = { ...competence };
        delete updatedCompetence.id;
        return await axios
          .put("/api/v1/admin/competence", JSON.stringify(updatedCompetence), {
            params: {
              competenceId: competence.id,
            },
            successText: "Компетенция сохранена",
          })
          .then(res => {
            if (!res.error) {
              res.indicators.sort((a, b) => a.indexNumber - b.indexNumber);
              commit("updateCompetence", res);
            }
            return res.error;
          });
      } else {
        return await axios
          .post("/api/v1/admin/competence", JSON.stringify(competence), {
            params: {
              profileId: profileId,
            },
            successText: "Компетенции обновлены",
          })
          .then(res => {
            if (!res.error) {
              commit("createCompetence", res);
            }
            return res.error;
          });
      }
    },

    async updateRatingScale({ commit }, [id, data]) {
      const ratingScale = {
        profileId: id,
        ...data,
      };

      return await axios
        .post("/api/v1/admin/rating_scale", JSON.stringify(ratingScale), {
          successText: "Баллы обновлены",
        })
        .then(res => {
          if (!res.error) {
            commit("updateRatingScale", data);
          } else {
            return res.error;
          }
        });
    },

    async updateReportSettings({ commit }, data) {
      if (!data.id) {
        delete data.id;
      }

      await axios
        .post("/api/v1/admin/profile/report-settings", JSON.stringify(data), {
          successText: "Отчет сохранен",
        })
        .then(res => {
          if (!res.error) {
            commit("updateReportSettings", data);
          } else {
            return res.error;
          }
        });
    },

    async fetchProfileSimualtion({ commit, getters }, id) {
      const response = {};
      const simulation = getters.getProfileSimulation;

      if (!simulation.simulationDate || !simulation.simulationTime) {
        await axios
          .get(`/api/v1/admin/simulation/date_time?profileId=${id}`)
          .then(res => {
            if (!res.error) {
              response.simulationDate = res.date;
              response.simulationTime = {
                beginTime: res.beginTime,
                endTime: res.endTime,
              };
            } else {
              return res.error;
            }
          });
      }

      if (!simulation.emails.length) {
        await axios
          .get(`/api/v1/admin/simulation/emails?profileId=${id}`)
          .then(res => {
            if (!res.error) {
              response.emails = res.data;
              response.emails.forEach(el => {
                const datetime = el.receiptTime.split("T");
                el.date = datetime[0];
                el.time = datetime[1].slice(0, -3);
              });
            } else {
              return res.error;
            }
          });
      }

      if (!simulation.events.length) {
        await axios
          .get(`/api/v1/admin/simulation/meetings?profileId=${id}`)
          .then(res => {
            if (!res.error) {
              response.events = res.data;
              response.events.forEach(el => {
                const datetime = el.date.split("T");
                el.date = datetime[0];
              });
            } else {
              return res.error;
            }
          });
      }

      if (!simulation.docs.length) {
        await axios
          .get(`/api/v1/admin/simulation/documents?profileId=${id}`)
          .then(res => {
            if (!res.error) {
              response.docs = res.data;
            } else {
              return res.error;
            }
          });
      }

      commit("setProfileSimulation", response);
    },

    updateSimulationDateTime: ({ commit }, data) =>
      axios
        .put("/api/v1/admin/simulation/date_time", null, {
          params: {
            profileId: data.profileId,
            beginDateTime: `${data.date}T${data.time.beginTime}`,
            endDateTime: `${data.date}T${data.time.endTime}`,
          },
          successText: "Дата и время в симуляции обновлены",
        })
        .then(() => commit("updateSimulationDateTime", data)),

    async updateProfileEmails({ commit, getters }) {
      await axios
        .get(
          `/api/v1/admin/simulation/emails?profileId=${getters.getCurrentProfile.id}`
        )
        .then(res => {
          if (!res.error) commit("updateProfileEmails", res.data);
        });
    },

    async updateProfileEvents({ commit, getters }) {
      await axios
        .get(
          `/api/v1/admin/simulation/meetings?profileId=${getters.getCurrentProfile.id}`
        )
        .then(res => {
          if (!res.error) commit("updateProfileEvents", res.data);
        });
    },

    async saveProfileDoc({ commit }, doc) {
      return await axios
        .post("/api/v1/admin/simulation/document", doc, {
          successText: doc.id ? "Документ обновлен" : "Документ создан",
        })
        .then(res => {
          if (!res.error) {
            if (doc.documentId) {
              doc.id = doc.documentId;
              delete doc.documentId;
              commit("updateProfileDoc", doc);
            } else {
              doc.id = res.id;
              delete doc.documentId;
              commit("createProfileDoc", doc);
            }
          }
          return res.error;
        });
    },

    async removeProfileDoc({ commit }, { docId }) {
      return await axios
        .delete("/api/v1/document", {
          params: {
            documentId: docId,
          },
          successText: "Документ удален",
        })
        .then(res => {
          if (!res.error) {
            commit("removeProfileDoc", docId);
          } else {
            return res.error;
          }
        });
    },

    async createProfileEmail({ commit }, { profileId, email }) {
      email.copy = email.copy ? email.copy : [];

      const insertedEmail = JSON.parse(JSON.stringify(email));
      insertedEmail.receiptTime = `${email.date} ${email.time}`;
      delete insertedEmail.date;
      delete insertedEmail.time;

      return await axios
        .post(
          `/api/v1/admin/simulation/email?profileId=${profileId}`,
          JSON.stringify(insertedEmail),
          { successText: "Письмо добавлено" }
        )
        .then(res => {
          if (!res.error) {
            email.id = res.id;
            commit("createProfileEmail", email);
          }
          return res.error;
        });
    },

    async updateProfileEmail({ commit }, { email }) {
      const insertedEmail = JSON.parse(JSON.stringify(email));
      insertedEmail.receiptTime = `${email.date} ${email.time}`;
      delete insertedEmail.date;
      delete insertedEmail.time;

      return await axios
        .put("/api/v1/admin/email_task", JSON.stringify(insertedEmail), {
          params: {
            emailId: email.id,
          },
          successText: "Письмо обновлено",
        })
        .then(res => {
          if (!res.error) {
            commit("updateProfileEmail", email);
          }
          return res.error;
        });
    },

    async removeProfileEmail({ commit }, { emailId }) {
      return await axios
        .delete("/api/v1/admin/email_task", {
          params: {
            emailId: emailId,
          },
          successText: "Письмо удалено",
        })
        .then(res => {
          if (!res.error) {
            commit("removeProfileEmail", emailId);
          } else {
            return res.error;
          }
        });
    },

    async createProfileEvent({ commit }, { profileId, event }) {
      event.copy = event.copy ? event.copy : [];

      const insertedEvent = JSON.parse(JSON.stringify(event));
      insertedEvent.date = `${event.date} ${event.startTime}`;
      insertedEvent.profileId = profileId;

      return await axios
        .post(
          `/api/v1/admin/simulation/meeting`,
          JSON.stringify(insertedEvent),
          { successText: "Встреча добавлена" }
        )
        .then(res => {
          if (!res.error) {
            event.id = res.id;
            commit("createProfileEvent", event);
          }
          return res.error;
        });
    },

    async updateProfileEvent({ commit }, { event }) {
      const insertedEvent = JSON.parse(JSON.stringify(event));
      insertedEvent.date = `${event.date} ${event.startTime}`;

      return await axios
        .put(
          "/api/v1/admin/simulation/meeting",
          JSON.stringify(insertedEvent),
          {
            params: {
              meetingId: event.id,
            },
            successText: "Событие обновлено",
          }
        )
        .then(res => {
          if (!res.error) {
            commit("updateProfileEvent", event);
          }
          return res.error;
        });
    },

    async removeProfileEvent({ commit }, { eventId }) {
      return await axios
        .delete("/api/v1/admin/simulation/meeting", {
          params: {
            meetingId: eventId,
          },
          successText: "Событие удалено",
        })
        .then(res => {
          if (!res.error) {
            commit("removeProfileEvent", eventId);
          } else {
            return res.error;
          }
        });
    },

    async createMaterial({ commit }, data) {
      return await axios
        .post(`/api/v1/admin/profile/material`, JSON.stringify(data), {
          successText: "Документ добавлен",
        })
        .then(res => {
          if (!res.error) {
            data.id = res.id;
            commit("pushMaterial", data);
          }
          return res.error;
        });
    },

    async updateMaterial({ commit }, data) {
      return await axios
        .put(
          "/api/v1/document",
          JSON.stringify({
            name: data.name,
            link: data.link,
          }),
          {
            params: {
              documentId: data.id,
            },
            successText: "Документ обновлен",
          }
        )
        .then(res => {
          if (!res.error) {
            commit("updateMaterial", data);
          }
          return res.error;
        });
    },

    async removeMaterial({ commit }, id) {
      return await axios
        .delete("/api/v1/document", {
          params: {
            documentId: id,
          },
          successText: "Документ удален",
        })
        .then(res => {
          if (!res.error) {
            commit("removeMaterial", id);
          } else {
            return res.error;
          }
        });
    },

    async updateQuestionnaire({ commit }, data) {
      return await axios
        .post(`/api/v1/admin/profile/questionnaire`, JSON.stringify(data), {
          successText: "Анкета обновлена",
        })
        .then(res => {
          if (!res.error) {
            data.id = res.id;
            commit("pushQuestionnaire", data);
          }
          return res.error;
        });
    },
  },
  getters: {
    getProfiles({ profiles }) {
      return profiles;
    },
    getCurrentProfile({ currentProfile }) {
      return currentProfile;
    },
    getProfileSimulation({ currentProfile }) {
      return currentProfile.simulation;
    },
    getProfileReportSettings({ currentProfile }) {
      return currentProfile.reportSettings;
    },
    getProfileReportSettingsRoles({ currentProfile }) {
      return currentProfile.reportSettings?.map(({ role }) => role);
    },
  },
};
