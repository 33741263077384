<template>
  <v-app-bar flat>
    <div style="width: 250px">
      <v-img :src="companyLogo" max-width="60" max-height="50" contain></v-img>
    </div>
    <Nav
      :tabs="tabs"
      :currentTab="currentTab"
      @tab-selected="$emit('tab-selected', $event)"
    />
    <nav class="d-flex nav justify-end" style="width: 250px">
      <v-menu offset-y>
        <!--Current role-->
        <template v-slot:activator="{ on }">
          <ul>
            <li class="nav-item" v-on="on">
              <span class="role_name">
                {{ currentRoleTitle }}
              </span>
              <v-icon>mdi-chevron-down</v-icon>
            </li>
          </ul>
        </template>

        <v-list dense class="py-0" :style="{ 'font-size': '12px' }">
          <!--List of availiable roles-->
          <v-list-item
            class="list-item"
            v-for="item in additionalRoles"
            :key="item.name"
            @click="changeRole(item)"
          >
            {{ item.title }}
          </v-list-item>
          <v-divider />

          <v-list-item
            class="list-item"
            @click="$emit('tab-selected', 'Company')"
            v-if="isAdmin"
          >
            О компании
          </v-list-item>

          <v-list-item
            class="list-item"
            @click="$emit('tab-selected', 'MyData')"
          >
            Мои данные
          </v-list-item>
          <v-divider />

          <v-list-item
            v-if="isParticipant"
            class="list-item"
            @click="$emit('tab-selected', 'Instructions')"
          >
            Инструкции
          </v-list-item>
          <v-divider />

          <v-list-item class="list-item" @click="logout">
            <span>Выход</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </nav>
  </v-app-bar>
</template>

<script>
import { computed } from "@vue/composition-api";
import Nav from "@/components/shared/assets/Nav";

export default {
  props: {
    tabs: Array,
    currentTab: String,
  },
  setup(_, { root }) {
    const store = root.$store;

    const companyLogo = computed(() => store.getters.getCompanyLogo);

    const currentRoleTitle = computed(() =>
      store.getters.getRoleTitle(store.getters.getCurrentRole.toUpperCase())
    );

    const isAdmin = computed(() => store.getters.getCurrentRole === "admin");
    const isParticipant = computed(
      () => store.getters.getCurrentRole === "participant"
    );

    const additionalRoles = computed(() => store.getters.getRoleList.slice(1));

    const logout = async () => await store.dispatch("logout");

    const changeRole = role => {
      localStorage.removeItem("lastTab");
      store.commit("changeRole", role);
    };

    return {
      companyLogo,
      currentRoleTitle,
      isAdmin,
      isParticipant,
      additionalRoles,
      logout,
      changeRole,
    };
  },
  components: {
    Nav,
  },
};
</script>

<style lang="scss">
header {
  border: none !important;
}
</style>
