import axios from "@/axios";
export default {
  state: {
    participantQuestionnaire: {},
  },
  mutations: {
    setParticipantQuestionnaire(state, data) {
      state.participantQuestionnaire = data;
    },
    setParticipantAnswers() {},
  },
  actions: {
    async fetchParticipantQuestionnaire({ commit }) {
      return await axios.get("/api/v1/participant/questionnaire").then(res => {
        if (!res.error) {
          commit("setParticipantQuestionnaire", res);
        } else {
          return res.error;
        }
      });
    },
    async sendParticipantAnswers({ commit }, data) {
      return await axios
        .post(
          `/api/v1/participant/questionnaire`,
          JSON.stringify({
            answers: data,
          }),
          { successText: "Ответ сохранен" }
        )
        .then(res => {
          if (!res.error) {
            commit("setParticipantAnswers");
          }
          return res.error;
        });
    },
  },
  getters: {
    getParticipantQuestionnaire: s => s.participantQuestionnaire,
  },
};
