import { computed, ref } from "@vue/composition-api";

export function useSessionStatus() {
  const getLocaleStatus = status => {
    switch (status) {
      case "PENDING":
        return "Назначена оценка";
      case "IN_PROGRESS":
        return "Идет оценка";
      case "FINISHED":
        return "Оценка завершена";
      case "REPORT_STARTED":
        return "Отчет в работе";
      case "REPORT_CREATED":
        return "Отчет готов";
      case "FEEDBACK_RECEIVED":
        return "Получена обратная связь";
      case "REPORT_PUBLISHED":
        return "Отчет опубликован";
    }
  };

  return {
    getLocaleStatus,
  };
}

export function useIndicators() {
  const parseCompetences = competences => {
    return competences.reduce((acc, el) => {
      return acc.concat(...el.indicators);
    }, []);
  };

  return {
    parseCompetences,
  };
}

export function useSimulationTimer() {
  const seconds = ref(0);

  const timer = computed(() => {
    let hours = Math.floor(seconds.value / 60 / 60);
    let minutes = Math.floor(seconds.value / 60) - hours * 60;

    if (hours)
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;

    if (minutes) return `0:${minutes.toString().padStart(2, "0")}`;
  });

  let timerInterval = null;

  const startTimer = (
    simulationTs,
    simulationEndTs,
    remainingSimulationSeconds
  ) => {
    clearInterval(timerInterval);

    let [h, m] = new Date(simulationTs).toTimeString().substr(0, 5).split(":");

    const limit =
      (Number(new Date(simulationEndTs)) - Number(new Date(simulationTs))) /
      1000;

    seconds.value =
      (+h * 60 + +m) * 60 +
      (limit -
        (remainingSimulationSeconds <= 0 ? 0 : remainingSimulationSeconds));

    timerInterval = setInterval(() => {
      if (remainingSimulationSeconds <= 0) {
        remainingSimulationSeconds = 0;
        clearInterval(timerInterval);
      } else {
        remainingSimulationSeconds--;
      }

      seconds.value =
        (+h * 60 + +m) * 60 + (limit - remainingSimulationSeconds);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerInterval);
  };

  return {
    timer,
    startTimer,
    stopTimer,
  };
}
