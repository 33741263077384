import axios from "@/axios";

const getOffset = () => {
  const offsetMinutes = new Date().getTimezoneOffset() * -1;
  return `${offsetMinutes >= 0 ? "+" : "-"}${
    offsetMinutes / 60 >= 10 ? "" : "0"
  }${Math.abs(Math.ceil(offsetMinutes / 60))}:${
    offsetMinutes % 60 ? "30" : "00"
  }`;
};

export default {
  state: {
    participantAssessmentSession: {},
  },
  mutations: {
    setParticipantAssessmentSession(state, data) {
      state.participantAssessmentSession = data;
    },
  },
  actions: {
    async fetchParticipantAssessmentSession({ commit }) {
      await axios
        .get("/api/v1/participant/assessment_session?offset=" + getOffset())
        .then(res => commit("setParticipantAssessmentSession", res));
    },
  },
  getters: {
    getParticipantAssessmentSession: state =>
      state.participantAssessmentSession,
  },
};
