<template>
  <div
    :style="{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      zIndex: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.35)',
    }"
    class="preloader-global"
  >
    <v-progress-circular
      :size="80"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
.preloader-global
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 100
</style>
