import axios from "@/axios";

export default {
  state: {
    assessmentTasksShort: [],
    currentAssessmentTask: {},
    selectedCompetence: {},
    selectedIndicator: {},
  },
  mutations: {
    setAssessmentTasks(state, data) {
      state.assessmentTasksShort = data;
    },
    setCurrentAssessmentTask(state, data) {
      data.competences
        .sort((a, b) => a.indexNumber - b.indexNumber)
        .forEach(c => {
          c.indicators
            .sort((a, b) => a.indexNumber - b.indexNumber)
            .forEach(i => {
              i.comment = i.expertComment;
              i.additionalExpertComment = i.additionalComment;
              i.newMark = null;

              i.levelMarks.sort((a, b) => {
                a.levelNumber = isNaN(+a.level) ? -1 : +a.level;
                b.levelNumber = isNaN(+b.level) ? -1 : +b.level;

                return a.levelNumber - b.levelNumber;
              });
            });
        });

      data.notes.forEach((el, index) => (el.id = String(index)));

      state.currentAssessmentTask = { ...data };
      state.selectedCompetence = data.competences[0];
      state.selectedIndicator = state.selectedCompetence?.indicators?.[0];
    },
    clearAssessmentTasks(state) {
      state.assessmentTasksShort = [];
      state.currentAssessmentTask = {};
      state.selectedCompetence = {};
      state.selectedIndicator = {};
    },
    setSelectedCompetence(state, competence) {
      state.selectedCompetence = competence;
      state.selectIndicator = competence.indicators?.[0];
    },
    setSelectedIndicator(state, indicator) {
      state.selectedIndicator = indicator;
    },
    updateReportIndicatorComment(state, text) {
      state.selectedIndicator.comment = text;
    },
    setVideoComment: (state, comment) =>
      (state.currentAssessmentTask.videoComment = comment),
    setVideoCommentForReport: (state, comment) =>
      (state.currentAssessmentTask.videoCommentForReport = comment),
    setIndicatorMark: (state, markId) => {
      state.selectedIndicator.estimatedLevelMarkId = markId;
      state.selectedIndicator.newMark = null;
    },
    setIndicatorComment: (state, text) =>
      (state.selectedIndicator.comment = text),
    setIndicatorAdditionalComment: (state, text) =>
      (state.selectedIndicator.additionalComment = text),
  },
  actions: {
    async fetchAssessmentTasks({ commit }, sessionId) {
      await axios
        .get("/api/v1/expert/session/tasks?sessionId=" + sessionId)
        .then(res => commit("setAssessmentTasks", res.tasks));
    },

    async fetchCurrentAssessmentTask({ commit }, { taskId, sessionId }) {
      await axios
        .get(
          `/api/v1/expert/session/task?taskId=${taskId}&sessionId=${sessionId}`
        )
        .then(res => commit("setCurrentAssessmentTask", res));
    },

    async setIndicatorMark({ commit }, data) {
      return await axios
        .post(
          `/api/v1/expert/session/indicator/level_mark`,
          JSON.stringify(data),
          { successText: "Оценка сохранена" }
        )
        .then(() => commit("setIndicatorMark", data.levelMarkId));
    },

    async setSessionIndicatorComment({ commit }, data) {
      return await axios
        .post(`/api/v1/expert/session/indicator/comment`, JSON.stringify(data))
        .then(() => commit("setIndicatorComment", data.text));
    },

    async setSessionIndicatorAdditionalComment({ commit }, data) {
      return await axios
        .post(
          `/api/v1/expert/session/indicator/additional_comment`,
          JSON.stringify(data),
          { successText: "Комментарий сохранен" }
        )
        .then(() => commit("setIndicatorAdditionalComment", data.text));
    },

    setVideoComment: ({ commit }, data) =>
      axios
        .post(
          "/api/v1/expert/session/video/comment?sessionId=" + data.sessionId,
          data.comment || " ",
          { successText: "Комментарий сохранен" }
        )
        .then(() => commit("setVideoComment", data.comment)),

    setVideoCommentForReport: ({ commit }, data) =>
      axios
        .post(
          "/api/v1/expert/session/video/comment_for_report?sessionId=" +
            data.sessionId,
          data.comment || " ",
          { successText: "Комментарий сохранен" }
        )
        .then(() => commit("setVideoCommentForReport", data.comment)),
  },
  getters: {
    getAssessmentTasks: state => state.assessmentTasksShort,
    getCurrentAssessmentTask: state => state.currentAssessmentTask,
    getSelectedCompetence: state => state.selectedCompetence,
    getSelectedIndicator: state => state.selectedIndicator,
  },
};
