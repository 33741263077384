<template>
  <v-app style="max-height: 100vh; min-height: 100vh; overflow: auto">
    <v-main style="min-width: 900px">
      <router-view></router-view>
      <Cookie v-if="!cookieConfirmed" @confirmed="confirmCookie" />
    </v-main>
  </v-app>
</template>

<script>
import { ref } from "@vue/composition-api";
import Cookie from "@/components/policy/Cookie";

export default {
  setup() {
    const cookieConfirmed = ref(localStorage.getItem("cookie-policy") || false);
    const confirmCookie = () => {
      cookieConfirmed.value = true;
      localStorage.setItem("cookie-policy", 1);
    };

    return {
      cookieConfirmed,
      confirmCookie,
    };
  },
  components: {
    Cookie,
  },
};
</script>
