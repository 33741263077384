<template>
  <div>
    <div>
      <slot></slot>
    </div>
    <confirm
      v-show="false"
      persistent
      :extDialog="saveConfirm"
      @cancel="saveConfirm = false"
      @confirm="save(saveFunction), (saveConfirm = false)"
      text="Сохранить изменения?"
    >
    </confirm>
  </div>
</template>

<script>
import { ref, provide } from "@vue/composition-api";

export default {
  setup() {
    const saveConfirm = ref(false);

    const saveFunction = ref(null);

    const save = async f => {
      await f();
    };

    const openSave = f => {
      saveFunction.value = f;
      saveConfirm.value = true;
    };

    provide("save-confirm", openSave);

    return {
      saveConfirm,
      openSave,
      saveFunction,
      save,
    };
  },
};
</script>
