<template>
  <v-container>
    <text-btn
      v-if="isParticipant"
      icon="mdi-arrow-left"
      @click="$emit('tab-selected', 'Main')"
    >
      На главную
    </text-btn>

    <v-row>
      <v-col>
        <v-card>
          <v-list class="pa-0">
            <v-list-item-group mandatory v-model="selectedIndex">
              <v-list-item
                v-for="(instr, index) in instructionList"
                :key="index"
                class="list-item"
              >
                <v-list-item-content>
                  {{ index + 1 }}. {{ instr.text }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card style="min-height: 27vw" class="d-flex flex-column">
          <iframe
            class="d-flex flex-grow-1"
            type="text/html"
            allowfullscreen
            :src="currentInstruction.url"
          />
        </v-card>
      </v-col>
    </v-row>

    <p class="hint mt-4">
      При появлении вопросов напишите нам на почту &laquo;<span class="subtitle"
        >ams.support@mozlab.ru</span
      >&raquo;
    </p>
  </v-container>
</template>

<script>
import { ref, computed, onMounted } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const isParticipant = store.getters.getCurrentRole === "participant";

    const instructions = {
      admin: [
        {
          text: "Обзор основного функционала администратора системы",
          url: "https://www.youtube.com/embed/ynhQltRNS1w",
        },
        {
          text: 'Обзор вкладки "Пользователи" и ролей в системе',
          url: "https://www.youtube.com/embed/iwUmMWUk0Ws",
        },
        {
          text: "Регистрация новых пользователей в системе",
          url: "https://www.youtube.com/embed/4_oVvXtQzkg",
        },
        {
          text: "Присвоение новой роли зарегистрированному пользователю",
          url: "https://www.youtube.com/embed/AP4WxkDtses",
        },
        {
          text: "Редактирование информации о пользователях",
          url: "https://www.youtube.com/embed/DCQc6haPybw",
        },
        {
          text: "Удаление пользователя из системы",
          url: "https://www.youtube.com/embed/5IaNZkVZKhs",
        },
        {
          text: "Удаление роли у пользователя с несколькими ролями в системе",
          url: "https://www.youtube.com/embed/ZURVeVSlHVM",
        },
        {
          text:
            'Обзор вкладки "Оценочные сессии" и просмотр подробной' +
            " информации об оценочной сессии",
          url: "https://www.youtube.com/embed/NxiyPlEVQFk",
        },
        {
          text: "Создание новой оценочной сессии",
          url: "https://www.youtube.com/embed/EmjSfqd1lME",
        },
        {
          text: "Редактирование информации об оценочной сессии",
          url: "https://www.youtube.com/embed/FnJKtt8Zwmk",
        },
        {
          text: "Удаление оценочных сессий из системы",
          url: "https://www.youtube.com/embed/3cT3yUIzWpg",
        },
        {
          text: 'Обзор вкладки "Методология"',
          url: "https://www.youtube.com/embed/IZREGz5wb5U",
        },
        {
          text: "Создание нового профиля оценки",
          url: "https://www.youtube.com/embed/mOWtsOtIx_A",
        },
      ],
      expert: [
        {
          text: "Как подготовиться к оценочной сессии",
          url: "https://www.youtube.com/embed/OPb0kjGy0dE",
        },
        {
          text: "Как найти материалы для подготовки к оценочной сессии",
          url: "https://www.youtube.com/embed/lUImTUxOKqo",
        },
        {
          text: "Как подключиться к симуляции рабочего дня",
          url: "https://www.youtube.com/embed/DnPi3Cn1IxE",
        },
        {
          text: "Как подключиться к видеочату в симуляции рабочего дня",
          url: "https://www.youtube.com/embed/48VlRQL9vV8",
        },
        {
          text: "Обзор симуляции рабочего дня",
          url: "https://www.youtube.com/embed/TSzZNgXWmg0",
        },
        {
          text: "Как запустить оценочную сессию",
          url: "https://www.youtube.com/embed/mYlxOO3EEHE",
        },
        {
          text: "Как проводить встречи во время симуляции рабочего дня",
          url: "https://www.youtube.com/embed/loSe3i_vOVM",
        },
        {
          text: "Как выключить камеру и микрофон в видеочате",
          url: "https://www.youtube.com/embed/eYUmWLp4YKU",
        },
        {
          text: "Как выйти из оценочной сессии, не завершая оценочную сессию",
          url: "https://www.youtube.com/embed/KI-J7pUVK3g",
        },
        {
          text: "Как завершить оценочную сессию",
          url: "https://www.youtube.com/embed/VS5d_uX7Ce8",
        },
        {
          text: "Как вставить ссылку на видеозапись оценочной сессии в систему",
          url: "https://www.youtube.com/embed/ecQQ1Y4L_T0",
        },
        {
          text: "Как составлять отчет по итогам оценки",
          url: "https://www.youtube.com/embed/W09fG2KI3wo",
        },
        {
          text: "Как редактировать отчет",
          url: "https://www.youtube.com/embed/rArn6OhXJPM",
        },
        {
          text: "Как посмотреть опубликованный отчет в системе",
          url: "https://www.youtube.com/embed/l7dSM3kHhz8",
        },
        {
          text: "Как проверить технический доступ к видеочату в системе",
          url: "https://www.youtube.com/embed/JuxGZ69BIh8",
        },
      ],
      observer: [
        {
          text: "Как подготовиться к центру оценки или развития",
          url: "https://www.youtube.com/embed/IXsDZ7yPNYk",
        },
        {
          text: "Как подключиться к центру оценки или развития",
          url: "https://www.youtube.com/embed/To6mx17Z-To",
        },
        {
          text: "Обзор симуляции рабочего дня",
          url: "https://www.youtube.com/embed/-a0zymuImcM",
        },
        {
          text: "Как посмотреть отчет в системе",
          url: "https://www.youtube.com/embed/raP1FeZzUlg",
        },
        {
          text: "Как проверить технический доступ к видеочату в системе",
          url: "https://www.youtube.com/embed/JuxGZ69BIh8",
        },
      ],
      executive: [
        {
          text: "Как подготовиться к центру оценки или развития",
          url: "https://www.youtube.com/embed/IXsDZ7yPNYk",
        },
        {
          text: "Как подключиться к центру оценки или развития",
          url: "https://www.youtube.com/embed/To6mx17Z-To",
        },
        {
          text: "Обзор симуляции рабочего дня",
          url: "https://www.youtube.com/embed/-a0zymuImcM",
        },
        {
          text: "Как посмотреть отчет в системе",
          url: "https://www.youtube.com/embed/pzTiyh5vXIA",
        },
        {
          text: "Как проверить технический доступ к видеочату в системе",
          url: "https://www.youtube.com/embed/JuxGZ69BIh8",
        },
      ],
      participant: [
        {
          text: "Обзор системы: дата и время, 4 действия в системе",
          url: "https://www.youtube.com/embed/oU5CstwZszA",
        },
        {
          text: "Как изучить материалы для подготовки перед оценкой",
          url: "https://www.youtube.com/embed/pqvXvDflJtI",
        },
        {
          text: "Как подключиться к оценке",
          url: "https://www.youtube.com/embed/fg2oPzhAEBA",
        },
        {
          text: 'Что делать, если кнопка "Перейти к оценке" не активна',
          url: "https://www.youtube.com/embed/CPdQSwhS7xw",
        },
        {
          text: "Как заполнить анкету обратной связи",
          url: "https://www.youtube.com/embed/DVpy0FqtFq8",
        },
        {
          text: "Как посмотреть отчет в системе",
          url: "https://www.youtube.com/embed/8-ugl6rlhAw",
        },
        {
          text: "Как проверить технический доступ к видеочату в системе",
          url: "https://www.youtube.com/embed/JuxGZ69BIh8",
        },
      ],
    };

    const instructionList = instructions[store.getters.getCurrentRole];

    const selectedIndex = ref(0);
    const currentInstruction = computed(
      () => instructionList[selectedIndex.value]
    );

    onMounted(() => localStorage.setItem("lastTab", "Instructions"));

    return {
      isParticipant,
      instructionList,
      selectedIndex,
      currentInstruction,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  box-shadow: 0 0 2px lightgrey;
}
</style>
