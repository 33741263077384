<template>
  <div class="mb-3">
    <p
      v-if="cases.title"
      class="subtitle mb-1 primary--text"
      :style="{
        position: 'relative',
        paddingRight: '18px',
      }"
    >
      <slot>{{ cases.title }}</slot
      >:
      <v-hover
        v-if="help"
        v-slot="{ hover }"
        :style="{
          position: 'absolute',
          top: '0',
          right: '0',
        }"
      >
        <div>
          <v-icon color="#767676" small class="text-center"
            >mdi-help-circle
          </v-icon>
          <div style="position: relative">
            <div
              v-if="hover"
              class="elevation-3 pa-3 white text--secondary"
              style="
                position: absolute;
                z-index: 100;
                overflow-y: auto;
                max-height: 200px;
                width: 300px;
                right: 0;
              "
            >
              {{ help }}
            </div>
          </div>
        </div>
      </v-hover>
    </p>
    <v-card
      v-if="data.length"
      class="mb-1"
      elevation="3"
      :style="{
        maxHeight: height,
        overflowY: 'auto',
      }"
    >
      <v-list dense class="py-0">
        <v-list-item class="brd-b" v-for="(item, idx) in data" :key="idx">
          <v-list-item-content>
            <div
              style="max-width: 100%"
              class="d-flex justify-space-between align-center"
            >
              <p
                v-if="!links"
                :style="{
                  maxWidth: edit || remove ? 'calc(100% - 80px)' : '100%',
                  lineHeight: '20px',
                }"
                v-html="stringArray ? item : item[itemTitle]"
              ></p>
              <p
                v-else
                :style="{
                  maxWidth: edit || remove ? 'calc(100% - 80px)' : '100%',
                  lineHeight: '20px',
                }"
              >
                <a :href="item.link" target="_blank">{{ item[itemTitle] }}</a>
              </p>
              <div class="flex-shrink-0">
                <v-btn v-if="edit" icon @click.stop="$emit('edit', item)">
                  <v-icon color="#767676" style="font-size: 16px"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <confirm
                  v-if="remove"
                  icon
                  :text="cases.remove || 'Подтвердите удаление'"
                  @confirm="$emit('remove', item)"
                >
                  <v-icon color="#767676" style="font-size: 16px"
                    >mdi-delete</v-icon
                  >
                </confirm>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <text-btn v-if="add" icon="mdi-plus" @click="$emit('add')">{{
      cases.add
    }}</text-btn>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  props: {
    cases: {
      type: Object,
      default: () => {
        return {
          title: "Список",
          add: "Добавить",
          remove: "Подтвердите удаление",
        };
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    itemTitle: {
      type: String,
      default: "name",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Boolean,
      default: false,
    },
    stringArray: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "300px",
    },
    help: {
      type: String,
      default: null,
    },
  },
  setup() {
    const removeDialog = ref(false);
    const hover = ref(false);

    return {
      removeDialog,
      hover,
    };
  },
};
</script>

<style lang="scss"></style>
