import axios from "@/axios";

export default {
  state: {
    clients: [],
  },
  mutations: {
    setClients(state, data) {
      state.clients = data;
    },
    pushClient(state, data) {
      state.clients.push(data);
    },
    updateClient(state, [data, id]) {
      const updatedClient = state.clients.find(el => el.id == id);

      Object.keys(data).forEach(key => {
        updatedClient[key] = data[key];
      });
    },
    removeClients(state, data) {
      state.clients = state.clients.filter(el => !data.includes(el.id));
    },
  },
  actions: {
    async fetchClients({ commit, getters }) {
      if (getters.getClients.length) return;

      return await axios.get("/api/v1/super_admin/clients").then(res => {
        if (!res.error) {
          commit("setClients", res.data);
        } else {
          return res.error;
        }
      });
    },
    async createClient({ commit }, data) {
      delete data.isExternal;

      return await axios
        .post("/api/v1/super_admin/client", JSON.stringify(data))
        .then(res => {
          if (!res.error) {
            commit("pushClient", res);
          } else {
            return res.error;
          }
        });
    },
    async updateClient({ commit }, [client, id]) {
      const updatedClient = {
        companyName: client.companyName,
        phone: client.phone,
        email: client.email,
        address: client.address,
        isEnabledEmbeddedVideo: client.isEnabledEmbeddedVideo,
      };

      return await axios
        .put(
          "/api/v1/super_admin/client?tenant_id=" + id,
          JSON.stringify(updatedClient)
        )
        .then(res => {
          if (!res.error) {
            commit("updateClient", [updatedClient, id]);
          } else {
            return res.error;
          }
        });
    },
    async removeClients({ commit }, data) {
      const ids = data.map(el => el.id);

      return await axios
        .delete("/api/v1/super_admin/clients", {
          data: ids,
        })
        .then(res => {
          if (!res.error) {
            commit("removeClients", ids);
          } else {
            return res.error;
          }
        });
    },
  },
  getters: {
    getClients: state => state.clients,
    getClientById:
      ({ clients }) =>
      clientId => {
        return clients.find(el => el.id == clientId);
      },
  },
};
