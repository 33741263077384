import TextBtn from "./assets/TextBtn";
import Modal from "./assets/Modal";
import Confirm from "./assets/Confirm";
import List from "./assets/List";
import ExpansionList from "./assets/ExpansionList";
import Preloader from "./assets/Preloader";
import SaveConfirm from "./assets/SaveConfirm";
import InfinityCollection from "./assets/InfinityCollection";

export default {
  "text-btn": TextBtn,
  modal: Modal,
  confirm: Confirm,
  list: List,
  "expansion-list": ExpansionList,
  preloader: Preloader,
  "save-confirm": SaveConfirm,
  "infinity-collection": InfinityCollection,
};
