import axios from "@/axios";

const getBaseUrl = getters =>
  `/api/v1/${
    getters.getCurrentRole === "executive" ? "leader" : getters.getCurrentRole
  }/simulation`;

export default {
  state: {
    simulation: {},
    settings: {
      syncRate: 60000,
      syncInterval: 0,
      timerInterval: 0,
    },
  },
  mutations: {
    setSimulation: (state, simulation) => {
      state.simulation.incomingEmails?.forEach(el => {
        simulation.incomingEmails.find(email => email.id === el.id).draft =
          el.draft;
      });
      state.simulation = simulation;
    },
    pollSimulation: (state, simulation) => {
      state.simulation = { ...state.simulation, ...simulation };
    },
    clearSimulation: state => (state.simulation = {}),
    setSyncInterval: (state, intervalId) =>
      (state.settings.syncInterval = intervalId),
    setTimerInterval: (state, intervalId) =>
      (state.settings.timerInterval = intervalId),
    clearIntervals: state => {
      if (state.settings.syncInterval) {
        clearInterval(state.settings.syncInterval);
        state.settings.syncInterval = 0;
      }
      if (state.settings.timerInterval) {
        clearInterval(state.settings.timerInterval);
        state.settings.timerInterval = 0;
      }
    },
    decreaseTimer: state => (state.simulation.remainingSimulationSeconds -= 1),
    setNote: (state, data) => {
      switch (data.role) {
        case "participant": {
          state.simulation.participantNote = data.note;
          return;
        }
        case "expert": {
          state.simulation.expertNote = data.note;
          return;
        }
        case "executive": {
          state.simulation.note = data.note;
          return;
        }
      }
    },
    startWorkTime: state => (state.simulation.workStartTs = Date.now()),
    updateDraft: (state, data) =>
      (state.simulation.incomingEmails.find(
        el => el.id === data.messageId
      ).draft = Object.assign(
        {},
        state.simulation.incomingEmails.find(el => el.id === data.messageId)
          .draft,
        data.draft
      )),
    replyEmail: (state, data) =>
      (state.simulation.sentEmails = {
        ...state.simulation.sentEmails,
        [data.emailId]: data.reply,
      }),
    toggleImportant: (state, data) => {
      if (data.type === "incoming") {
        const index = state.simulation.incomingEmails.findIndex(
          el => el.id === data.emailId
        );
        state.simulation.incomingEmails[index] = {
          ...state.simulation.incomingEmails[index],
          ...{ important: data.isImportant },
        };
      } else
        state.simulation.sentEmails[data.emailId] = {
          ...state.simulation.sentEmails[data.emailId],
          ...{ important: data.isImportant },
        };
    },
  },
  actions: {
    fetchSimulation: ({ commit, getters }, sessionId = null) =>
      axios
        .get(
          `${getBaseUrl(getters)}${sessionId ? "?sessionId=" + sessionId : ""}`
        )
        .then(res => {
          res.incomingEmails.sort(
            (a, b) =>
              Number(new Date(b.receiptTime)) - Number(new Date(a.receiptTime))
          );
          res.meetings.sort(
            (a, b) => Number(new Date(a.date)) - Number(new Date(b.date))
          );
          if (getters.getCurrentRole !== "participant") {
            res.tasks.forEach(el => {
              el.competences.forEach(el =>
                el.indicators.sort((a, b) => a.indexNumber - b.indexNumber)
              );
              el.competences.sort((a, b) => a.indexNumber - b.indexNumber);
            });
          }
          if (!res.sentEmails) res.sentEmails = {};
          commit("setSimulation", res);
        }),
    pollSimulation: ({ commit, getters }) =>
      axios
        .get(
          `${getBaseUrl(getters)}/poll${
            "?simulationId=" + getters.getSimulationId
          }`
        )
        .then(res => {
          if (getters.getCurrentRole === "participant") {
            delete res["participantNote"];
            delete res["othersNotes"];
            delete res["sentEmails"];
          }
          commit("pollSimulation", res || {});
        }),
    startSyncSimulation: ({ commit, getters, dispatch }) => {
      if (getters.getSyncInterval) commit("clearIntervals");
      const syncIntervalId = setInterval(
        () => dispatch("pollSimulation"),
        getters.getSyncRate
      );
      commit("setSyncInterval", syncIntervalId);
      const timerIntervalId = setInterval(() => commit("decreaseTimer"), 1000);
      commit("setTimerInterval", timerIntervalId);
    },
    setSimulationNote: ({ commit, getters }, data) =>
      axios
        .post(`${getBaseUrl(getters)}/note`, data, {
          successText: "Заметка сохранена",
        })
        .then(() =>
          commit("setNote", {
            note: data,
            role: getters.getCurrentRole,
          })
        ),
    startWorkTime: ({ commit, getters, dispatch }) =>
      axios
        .put(
          "/api/v1/expert/simulation/work_time/start?sessionId=" +
            getters.getSimulation.sessionId +
            "&simulationId=" +
            getters.getSimulation.id,
          {},
          { successText: "Время запущено" }
        )
        .then(() => {
          commit("startWorkTime");
          dispatch("startSyncSimulation");
        }),
    finishSession: ({ commit, getters }) =>
      axios
        .post(
          "/api/v1/expert/assessment_session/finish?sessionId=" +
            getters.getSimulation.sessionId +
            "&simulationId=" +
            getters.getSimulation.id,
          {},
          { successText: "Сессия завершена" }
        )
        .then(() => {
          commit("setSessionFinished", getters.getSimulation.sessionId);
          commit("clearSimulation");
        }),
    replyEmail: ({ commit }, data) =>
      axios
        .post(
          `/api/v1/participant/simulation/email/reply?email_id=${data.emailId}&simulation_id=${data.simulationId}`,
          data.reply
        )
        .then(res => {
          data.reply.id = res.id;
          data.reply.receiptTime = new Date().toISOString();
          commit("replyEmail", {
            emailId: data.emailId,
            reply: data.reply,
          });
        }),
    toggleImportant: ({ commit }, data) =>
      axios
        .post(
          `/api/v1/participant/simulation/email/important?emailId=${data.emailId}&isImportant=${data.isImportant}`
        )
        .then(() => commit("toggleImportant", data)),
  },
  getters: {
    getSimulation: state => {
      const simulation = state.simulation;
      simulation.incomingEmails?.forEach(el => {
        if (!el.draft?.title)
          el.draft = {
            from: el.from,
            copy: [],
            title: "RE: " + el.title,
            text: "",
            important: false,
          }; // TODO: Remove
      });
      return simulation;
    },
    getSimulationId: state => state.simulation.id,
    getSyncRate: state => state.settings.syncRate,
    getSyncInterval: state => state.settings.syncInterval,
  },
};
