import axios from "@/axios";

export default {
  state: {
    departments: [],
  },
  mutations: {
    setDepartments(state, data) {
      state.departments = data;
    },
    pushDepartment(state, data) {
      state.departments.unshift(data);
    },
    removeDepartment(state, id) {
      state.departments = state.departments.filter(el => el.id != id);
    },
    updateDepartment(state, { id, name }) {
      state.departments.find(el => el.id == id).name = name;
    },
  },
  actions: {
    async fetchDepartments({ commit, getters }) {
      if (getters.getDepartments.length) return;

      await axios.get("/api/v1/admin/departments").then(res => {
        if (!res.error) {
          commit("setDepartments", res.data);
        } else {
          return res.error;
        }
      });
    },
    async createDepartment({ commit, getters }, data) {
      if (getters.getDepartments.find(el => el.name == data)) {
        return `Подразделение с названием "${data}" уже существует`;
      }

      return await axios
        .post(`/api/v1/admin/department`, data, {
          successText: "Подразделение создано",
        })
        .then(res => {
          if (!res.error) {
            commit("pushDepartment", res);
          }
          return res.error;
        });
    },
    async removeDepartment({ commit }, id) {
      return await axios
        .delete("/api/v1/admin/department", {
          params: {
            id: id,
          },
          successText: "Отдел удален",
        })
        .then(res => {
          if (!res.error) {
            commit("removeDepartment", id);
          } else {
            return res.error;
          }
        });
    },
    async updateDepartment({ commit }, data) {
      return await axios
        .put("/api/v1/admin/department", data.name, {
          params: {
            id: data.id,
          },
          successText: "Отдел сохранен",
        })
        .then(res => {
          if (!res.error) {
            commit("updateDepartment", data);
          }
          return res.error;
        });
    },
  },
  getters: {
    getDepartments({ departments }) {
      return departments;
    },
  },
};
