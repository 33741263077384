import axios from "axios";
import store from "@/store";
import Vue from "vue";

import { loading } from "@/views/Main";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://backstaging.ams-moz.ru/"
    : "https://back.ams-moz.ru/";

const apiRequest = axios.create({
  baseURL: baseUrl,
  timeout: 120000,
  headers: { "Content-Type": "application/json" },
});

apiRequest.interceptors.response.use(
  response => {
    if (response.data.success === false) {
      loading.value = false;
      Vue.$toast.error(`Ошибка сервера: ${response.data.error}`);
      throw new Error(response.data.error);
    }
    if (
      ["post", "put", "delete"].includes(response.config.method) &&
      !response.config.isNotShowToast
    )
      Vue.$toast.success(
        response.config.successText || "Запрос выполнен успешно!"
      );
    return response.data ? response.data : response;
  },
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      if (store.getters.getAuthData) {
        Vue.$toast.warning("Текущая сессия завершена");
        store.dispatch("logout");
      } else Vue.$toast.error("Неверные имя и пароль");
    }
    if (
      error.response.status === 400 &&
      error.response.config.url !== "/api/v1/files/logo" &&
      !error.response.config.isIgnoreErrors
    ) {
      Vue.$toast.error(`Ошибка сервера: ${error.response.data.error}`);
    }
    loading.value = false;
    return error;
  }
);

export default apiRequest;
