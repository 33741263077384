import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "vuetify/es5/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4C5CA7",
        error: "#EA5D45",
        success: "#84AF5B",
        grey: "#E7E9F3",
        dark: "#121212",
      },
    },
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
});
