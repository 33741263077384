import { useDebounce } from "@/use/helpers";

export default function usePagination() {
  const { debounce } = useDebounce();

  let defaultPageSize = 10;
  const setSize = size => (defaultPageSize = size);
  const getPage = (data = []) => {
    return Math.floor(data.length / defaultPageSize);
  };

  const pagebleQuery = model => {
    return Number(defaultPageSize)
      ? `size=${defaultPageSize}&page=${
          model.number !== undefined ? model.number + 1 : 0
        }`
      : `size=${model.totalElements}`;
  };

  const incrementElement = state => {
    state.totalElements++;
    state.numberOfElements++;
  };

  const decrementElement = state => {
    state.totalElements--;
    state.numberOfElements--;
  };

  const getSelectMenu = element => {
    return document.querySelector(
      `#${
        element.querySelector("[role=button]").attributes["aria-owns"].nodeValue
      }`
    ).parentNode;
  };

  const scrollHandler = async (container, callback) => {
    const f = debounce(async e => {
      await callback();
      e.target.scrollTop--;
    }, 300);

    container.onscroll = async e => {
      let result;

      if (
        e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - 10
      ) {
        result = await f(e);
      }

      return result;
    };
  };

  const excludeDuplicates = collection => {
    collection = collection.filter(
      (el, idx, self) => idx === self.findIndex(u => u.id == el.id)
    );

    return collection;
  };

  const updateModel = (model, newData) => {
    return {
      ...newData,
      content: excludeDuplicates((model.content || []).concat(newData.content)),
    };
  };

  const clearModel = model => {
    model.content = [];
    model.first = true;
    model.last = false;

    return model;
  };

  return {
    defaultPageSize,
    setSize,
    getPage,
    pagebleQuery,
    incrementElement,
    decrementElement,
    scrollHandler,
    getSelectMenu,
    excludeDuplicates,
    updateModel,
    clearModel,
  };
}
