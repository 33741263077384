<template>
  <v-container>
    <Header
      v-if="!isHeaderHidden"
      :tabs="tabs"
      :currentTab="currentTab"
      @tab-selected="selectTab"
    />
    <component
      class="px-0"
      :is="getComponent"
      @tab-selected="selectTab($event || 'Main')"
    />
  </v-container>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  provide,
} from "@vue/composition-api";
import { useTabs } from "@/use/helpers";

import Header from "@/components/shared/assets/Header";
import Instructions from "@/components/shared/Instructions";
import MyData from "@/components/shared/MyData";

export const loading = ref(false);

export default {
  name: "MainView",
  setup(_, { root }) {
    const store = root.$store;

    const { currentTab, selectTab: defaultSelectTab } = useTabs();
    const selectTab = tabName => {
      if (tabName === "Reports") store.commit("clearCurrentReport");
      defaultSelectTab(tabName);
    };

    const role = computed(() => store.getters.getCurrentRole);

    const tabs = computed(() => {
      switch (role.value) {
        case "admin": {
          return [
            {
              name: "Users",
              label: "Пользователи",
            },
            {
              name: "AssessmentSessions",
              label: "Оценочные сессии",
            },
            {
              name: "Metodology",
              label: "Методология",
            },
            {
              name: "Instructions",
              label: "Инструкции",
            },
          ];
        }
        case "executive":
        case "observer":
        case "expert": {
          return [
            {
              name: "Sessions",
              label: "Оценочные сессии",
            },
            {
              name: "Reports",
              label: "Отчеты",
            },
            {
              name: "Instructions",
              label: "Инструкции",
            },
          ];
        }
        case "participant":
          return [];
      }
    });
    watch(
      () => role.value,
      () => (currentTab.value = tabs.value?.[0].name)
    );

    const isHeaderHidden = ref(false);
    provide("isHeaderHidden", isHeaderHidden);

    provide("loading", loading);

    onBeforeMount(async () => {
      let lastTab =
        localStorage.getItem("lastTab") || tabs.value[0]?.name || "Main";
      switch (lastTab) {
        case "Simulation": {
          await store
            .dispatch("fetchSimulation", localStorage.getItem("sessionId"))
            .catch(() => {
              localStorage.setItem("lastTab", tabs.value[0]?.name || "Main");
              lastTab = tabs.value[0]?.name || "Main";
            });
          break;
        }
        case "Report": {
          if (store.getters.getCurrentRole !== "admin")
            await store
              .dispatch("fetchCurrentReport", {
                sessionId: localStorage.getItem("sessionId"),
                role: store.getters.getCurrentRole,
              })
              .then(() => {
                if (store.getters.getCurrentReport.status !== "PUBLISHED")
                  store.commit("clearCurrentReport");
                lastTab = "Reports";
              });
          else lastTab = "Users";
          break;
        }
      }

      currentTab.value = lastTab;

      switch (role.value) {
        case "admin": {
          await Promise.all([
            store.dispatch("fetchUsers", {
              role: "participant",
              short: true,
              noSession: true,
            }),
            store.dispatch("fetchUsers", {
              role: "expert",
              short: true,
            }),
            store.dispatch("fetchUsers", {
              role: "executive",
              short: true,
            }),
            store.dispatch("fetchUsers", {
              role: "observer",
              short: true,
            }),

            store.dispatch("fetchAssessmentSessions"),

            store.dispatch("fetchUsers", {
              role: "participant",
              short: false,
              noSession: true,
            }),
            store.dispatch("fetchProfiles"),
          ]);
          break;
        }
        case "expert":
        case "executive":
        case "observer": {
          await Promise.all([
            store.dispatch("fetchSessions", role.value),
            store.dispatch("fetchReports"),
          ]);
          break;
        }
        case "participant":
          await store.dispatch("fetchParticipantAssessmentSession");
      }
      loading.value = false;
    });

    onBeforeUnmount(() => {
      // Admin
      store.commit("clearUsers");
      store.commit("clearUsers", { short: true });
      store.commit("clearAssessmentSessions");
      store.commit("clearProfiles");
      // Observer/Executive/Expert
      store.commit("clearSessions");
      store.commit("clearReports");
      // Participant
      store.commit("clearAssessmentSession");
    });

    const getComponent = computed(() => {
      switch (currentTab.value) {
        // Admin
        case "AssessmentSessions":
          return () => import("@/components/admin/AssessmentSessions");
        case "Users":
          return () => import("@/components/admin/Users");
        case "Metodology":
          return () => import("@/components/admin/Metodology");
        case "Company":
          return () => import("@/components/admin/Company");
        // Expert/Executive/Observer
        case "Sessions":
          return () => import("@/components/shared/Sessions");
        case "Reports":
          return () => import("@/components/shared/Reports");
        case "Help":
          return () => import("@/components/shared/Help");
        // Participant
        case "Main":
          return () => import("@/components/participant/Main");
        case "Preparing":
          return () => import("@/components/participant/Preparing");
        case "Results":
          return () => import("@/components/participant/Results");
        case "Questionnaire":
          return () => import("@/components/participant/Questionnaire");
        case "Report":
          return () => import("@/components/shared/reports/Report");
        case "Simulation":
          return () => import("@/components/shared/Simulation");
        // Shared
        case "MyData":
          return MyData;
        case "Instructions":
          return Instructions;
      }
    });

    return {
      currentTab,
      selectTab,
      tabs,
      isHeaderHidden,
      getComponent,
    };
  },
  components: {
    Header,
    Instructions,
    MyData,
  },
};
</script>
