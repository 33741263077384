import Vue from "vue";
import Router from "vue-router";

import Main from "@/views/Main.vue";

import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Auth.vue"),
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("@/views/Auth.vue"),
    },
    {
      path: "/admin",
      name: "admin",
      component: Main,
    },
    {
      path: "/super_admin",
      name: "super_admin",
      component: () => import("@/views/Superadmin.vue"),
    },
    {
      path: "/participant",
      name: "participant",
      component: Main,
    },
    {
      path: "/expert",
      name: "expert",
      component: Main,
    },
    {
      path: "/executive",
      name: "executive",
      component: Main,
    },
    {
      path: "/observer",
      name: "observer",
      component: Main,
    },
    {
      path: "/:catchAll(.*)*",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path === "/auth") {
    next();
    return;
  }

  if (to.path !== "/auth" && !store.getters.getAuthData) {
    next("/auth");
    return;
  }

  const targetRole = to.path.slice(1);
  if (store.getters.getCurrentRole === targetRole) {
    next();
    return;
  }

  if (
    store.getters.getAuthData.roles.indexOf(targetRole.toUpperCase()) !== -1
  ) {
    store.commit("changeRole", {
      name: targetRole.toUpperCase(),
      view: targetRole,
      isSamePage: true,
    });
    next();
    return;
  }

  next(
    `/${
      store.getters.getRoles[store.getters.getCurrentRole.toUpperCase()].view
    }`
  );
});

export default router;
