import axios from "@/axios";
import usePagination from "@/use/pagination";

const { incrementElement, decrementElement, pagebleQuery, updateModel } =
  usePagination();

export default {
  state: {
    participants: {},
    admins: {},
    experts: {},
    observers: {},
    executives: {},
    participantsShort: {},
    adminsShort: {},
    expertsShort: {},
    observersShort: {},
    executivesShort: {},
    allShortUsers: {},
    currentUser: {},
  },
  mutations: {
    setUsers(state, [model, users]) {
      state[model] = updateModel(state[model], users);
    },
    setCurrentUser(state, data) {
      state.currentUser = data;
    },
    pushUser(state, [model, user]) {
      if (!state[model].content) state.model.content = [];
      state[model].content.unshift(user);
      state[`${model}Short`].content.unshift({
        id: user.id,
        fullName:
          `${user.lastName} ${user.firstName} ${user.middleName}`.trim(),
      });
      incrementElement(state[model]);
      if (model !== "participants") {
        if (!state.allShortUsers.content) state.allShortUsers.content = [];
        state.allShortUsers.content.unshift({
          id: user.id,
          fullName:
            `${user.lastName} ${user.firstName} ${user.middleName}`.trim(),
        });
      }
    },
    updateUser(state, [model, user, id]) {
      const index = state[model].content.findIndex(el => el.id == id);
      state[model].content.splice(index, 1, {
        ...state[model].content[index],
        ...user,
      });
    },
    removeUsers(state, [model, usersId]) {
      if (state[model].content) {
        state[model].content = state[model].content.filter(
          el => !usersId.includes(el.id)
        );

        decrementElement(state[model]);
      }
    },
    clearAllShortUsers(state) {
      state.allShortUsers = [];
    },
    clearUsers(state, { short = false, model = null }) {
      if (model) {
        state[`${model}s${short ? "Short" : ""}`] = {};
      } else {
        state[`participants${short ? "Short" : ""}`] = {};
        state[`admins${short ? "Short" : ""}`] = {};
        state[`observers${short ? "Short" : ""}`] = {};
        state[`executives${short ? "Short" : ""}`] = {};
        state[`experts${short ? "Short" : ""}`] = {};
      }
    },
  },
  actions: {
    async fetchUsers(
      { commit, state },
      { role, short = false, noSession = false }
    ) {
      const model = state[`${role}s${short ? "Short" : ""}`];

      if (model.last) return;

      return await axios
        .get(
          `/api/v1/admin/users${short ? "/short" : ""}?role=${
            role === "executive" ? "LEADER" : role.toUpperCase()
          }&${pagebleQuery(model)}&noSession=${noSession}`
        )
        .then(res => {
          if (!short)
            res.content = res.content.map(el => {
              const index = el.roles.indexOf("LEADER");
              if (index !== -1) el.roles[index] = "EXECUTIVE";
              return el;
            });
          commit("setUsers", [`${role}s${short ? "Short" : ""}`, res]);
        });
    },
    async fetchUserById({ commit }, id) {
      return await axios
        .get(`/api/v1/admin/user/?id=${id}`)
        .then(res => commit("setCurrentUser", res));
    },
    async fetchUserByIdAllRoles({ commit }, id) {
      return await axios
        .get(`/api/v1/who_am_i?id=${id}`)
        .then(res => commit("setCurrentUser", res));
    },

    async createUser({ commit }, [role, user]) {
      if (!user["Position"]) delete user["Position"];
      if (!user["Department"]) delete user["Department"];
      user["profileId"] = user["profileName"];
      delete user["profileName"];
      const sendNotify = user["sendNotify"];
      delete user["sendNotify"];

      user.role =
        user.role === "executive" ? "LEADER" : user.role.toUpperCase();

      return await axios
        .post(
          `/api/v1/admin/user/new${sendNotify ? "?notify=true" : ""}`,
          user,
          { successText: "Пользователь создан" }
        )
        .then(res => commit("pushUser", [`${role}s`, res]));
    },

    async updateUser({ commit }, [role, user, id]) {
      let sendNotify = false;
      Object.keys(user).forEach(key => {
        if (
          (~key.indexOf("Position") || ~key.indexOf("Department")) &&
          !user[key]
        ) {
          delete user[key];
        } else if (key == "profileName" && !user["profileId"]) {
          user["profileId"] = user[key];
          delete user[key];
        } else if (key == "sendNotify") {
          sendNotify = user[key];
          delete user[key];
        }
      });

      user.newRole =
        user.newRole === "executive" ? "LEADER" : user.newRole?.toUpperCase();

      return await axios
        .post(
          `/api/v1/admin/user?id=${id}${sendNotify ? "&notify=true" : ""}`,
          JSON.stringify(user),
          { successText: "Пользователь обновлен" }
        )
        .then(res => {
          if (user.newRole) {
            res.assessmentPurpose = user.assessmentPurpose;
            commit("pushUser", [`${role}s`, res]);
          } else {
            commit("updateUser", [`${role}s`, res, id]);
          }
        });
    },

    async removeUsers({ commit }, data) {
      return await axios
        .delete("/api/v1/admin/users", {
          data: {
            ids: data.ids,
            role:
              data.role === "executive" ? "LEADER" : data.role.toUpperCase(),
          },
          successText: "Пользователь удален",
        })
        .then(() => commit("removeUsers", [`${data.role}s`, data.ids]));
    },
    resetPassword: async (_, email) =>
      await axios
        .post("/api/v1/admin/user/password/reset", email, {
          successText: "Пароль сброшен",
        })
        .then(res => res.error),
    async fetchAllShortUsers({ commit, state }, { notRole }) {
      if (state.allShortUsers.last) return;

      await axios
        .get(
          `/api/v1/admin/users/short?role-not=${
            notRole === "executive" ? "LEADER" : notRole.toUpperCase()
          }&${pagebleQuery(state.allShortUsers)}`
        )
        .then(res => {
          if (!res.error) {
            commit("setUsers", ["allShortUsers", res]);
          } else {
            return res.error;
          }
        });
    },
  },
  getters: {
    getUsers: (state, getters) => role =>
      state[`${role}s`].content?.map(el => {
        el.formattedRegisterDate = new Date(el.registerDate).toLocaleDateString(
          "ru-RU",
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
        );

        el.additionalRolesList = el.roles
          .map(el => getters.getRoles[el === "LEADER" ? "EXECUTIVE" : el].title)
          .join(", ");

        el.fullName = `${el.lastName} ${el.firstName} ${el.middleName}`.trim();

        for (let key in el) {
          if (
            !el[key] &&
            typeof el[key] != "boolean" &&
            ["firstName", "lastName", "middleName"].indexOf(key) === -1
          ) {
            el[key] = "-";
          }
        }

        el.localeRoles = el.roles.map(
          el => getters.getRoles[el === "LEADER" ? "EXECUTIVE" : el].title
        );

        if (role === "participant") {
          el.localeAssessmentPurpose =
            getters.getRoles.PARTICIPANT.assessmentPurpose[
              el.assessmentPurpose
            ];
        }

        return el;
      }) || [],
    getUserById: state => (role, userId) => {
      return state[`${role}s`].content.find(el => el.id == userId);
    },
    getShortUsers: state => role => {
      return state[`${role}sShort`] || {};
    },
    getAllShortUsers({ allShortUsers }) {
      allShortUsers.content =
        allShortUsers.content?.map(el => {
          el.name = el.fullName;
          return el;
        }) || [];

      return allShortUsers;
    },
    getCurrentUser({ currentUser }) {
      return currentUser;
    },
  },
};
