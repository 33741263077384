import axios from "@/axios";
import { useSessionStatus } from "@/use/sessions";
import usePagination from "@/use/pagination";

const { getLocaleStatus } = useSessionStatus();

const { pagebleQuery, updateModel } = usePagination();

export default {
  state: {
    sessions: {
      content: [],
    },
  },
  mutations: {
    setSessions(state, sessions) {
      state.sessions = updateModel(state.sessions, sessions);
    },
    clearSessions(state) {
      state.sessions = {
        content: [],
      };
    },
    setSessionFinished: (state, id) =>
      (state.sessions.content.find(el => el.id === id).status = "FINISHED"),
  },
  actions: {
    async fetchSessions({ commit, state }, role) {
      if (state.sessions.last) return;

      await axios
        .get(
          `/api/v1/assessment_sessions?role=${
            role === "executive" ? "LEADER" : role.toUpperCase()
          }&${pagebleQuery(state.sessions)}`
        )
        .then(res => {
          if (!res.error) {
            res.content.sort(
              (a, b) =>
                Number(new Date(b.startTimestamp)) -
                Number(new Date(a.startTimestamp))
            );

            commit("setSessions", res);
          } else {
            return res.error;
          }
        });
    },
  },
  getters: {
    getSessions({ sessions }, getters) {
      sessions.content.map(el => {
        el.localeAssessmentPurpose =
          getters.getRoles.PARTICIPANT.assessmentPurpose[
            el.assessmentPurpose
          ] || el.assessmentPurpose;

        el.assessmentSessionDate = new Date(el.startTimestamp).toLocaleString(
          "ru-RU",
          {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
          }
        );
        el.assessmentSessionStartTime = new Date(
          el.startTimestamp
        ).toLocaleString("ru-RU", {
          hour: "2-digit",
          minute: "2-digit",
        });

        el.assessmentSessionEndTime = new Date(el.endTimestamp).toLocaleString(
          "ru-RU",
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        );

        const [notifyDate, notifyTime] =
          el.notifyParticipantTimestamp.split("T");
        el.notifyDate = notifyDate;
        el.notifyTime = notifyTime.slice(0, 5);

        el.assessmentSessionTime = `${el.assessmentSessionStartTime} - ${el.assessmentSessionEndTime}`;

        el.localeStatus = getLocaleStatus(el.status) || el.status;

        const { lastName, firstName, middleName } = el.participantUser;
        el.fullName = `${lastName} ${firstName} ${middleName}`
          .trim()
          .replace(/-/g, "");

        el.participantUserId = el.participantUser.id;

        Object.keys(el.participantUser).forEach(key => {
          el.participantUser[key] = el.participantUser[key]
            ? el.participantUser[key]
            : "-";
        });

        el.expertsList.sort(a => {
          return a.id == el.mainExpertId ? -1 : 1;
        });

        return el;
      });

      return sessions;
    },
  },
};
