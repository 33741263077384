import { ref, computed, provide, reactive } from "@vue/composition-api";

export function useDebounce() {
  const debounce = (f, ms) => {
    let isCooldown = false;

    return function () {
      if (isCooldown) return;

      f.apply(this, arguments);

      isCooldown = true;

      setTimeout(() => (isCooldown = false), ms);
    };
  };

  return {
    debounce,
  };
}

export function useToggle() {
  const toggle = ref(false);
  const switchToggle = () => {
    toggle.value = !toggle.value;
  };

  return {
    toggle,
    switchToggle,
  };
}

export function useModalToggle() {
  const toggle = reactive({
    isOpen: false,
    view: "",
    width: 450,
  });

  const switchToggle = (
    isOpen = !toggle.isOpen,
    view = toggle.view,
    width = 450
  ) => {
    toggle.isOpen = isOpen;
    toggle.view = view;
    toggle.width = width;
  };
  return {
    toggle,
    switchToggle,
  };
}

export function useSetFilters(headers, content) {
  const comboFilter = ref({});
  const setFilters = tableHeaders => {
    tableHeaders.forEach(header => {
      if (header.filterable) {
        header.filter = function (value) {
          if (!comboFilter.value[header.value]) {
            return true;
          }
          return value === comboFilter.value[header.value];
        };
        return true;
      }
    });
  };

  setFilters(headers.value);
  provide(
    "headers",
    headers.value.filter(el => el.filterable)
  );
  provide("content", content);
  provide("comboFilter", comboFilter);

  return {
    comboFilter,
    setFilters,
  };
}

export function useLineBreakReplacer() {
  const replacer = (text = "") => {
    return text.replace(/\n/g, "<br/>");
  };
  return {
    replacer,
  };
}

export function useFormattedDates() {
  const digitDate = date => {
    return new Date(date).toLocaleString("ru-RU", {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
  };

  const digitTime = date => {
    return new Date(date).toLocaleTimeString("ru-RU", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getTimeZone = (date, time) => {
    let d = new Date(`${date}T${time}`);
    let offset = -(d.getTimezoneOffset() / 60);

    if (offset >= 0) {
      return offset.toString().padStart(3, "+0") + ":00";
    }

    return (-offset).toString().padStart(3, "-0") + ":00";
  };

  return {
    digitDate,
    digitTime,
    getTimeZone,
  };
}

export function useAuthRedirect(root) {
  const isAuth = ref(false);
  const authData = computed(() => root.$store.getters.getAuthData);

  if (
    authData.value &&
    authData.value.roles.indexOf(
      root.$router.history.current.name.toUpperCase()
    ) !== -1
  ) {
    root.$store.commit("changeRole", {
      name: root.$router.history.current.name.toUpperCase(),
      view: root.$router.history.current.name,
      isSamePage: true,
    });
  }

  if (authData.value) isAuth.value = true;
  else root.$router.push("/auth");

  return {
    isAuth,
    authData,
  };
}

export function useTabs(tab) {
  const defaultTab = tab;
  const currentTab = ref(defaultTab);

  const selectTab = tabName => {
    currentTab.value = tabName;
  };

  const setDefaultTab = () => {
    currentTab.value = defaultTab;
  };

  const isActiveTab = tabName => {
    return currentTab === tabName ? true : false;
  };

  return {
    defaultTab,
    currentTab,
    selectTab,
    setDefaultTab,
    isActiveTab,
  };
}

export function useLinks() {
  const validLink = link => {
    const testedLink = /^https?:\/\//.test(link);
    return testedLink ? link : `http://${link}`;
  };

  return {
    validLink,
  };
}
