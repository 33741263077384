import Vue from "vue";
import Vuex from "vuex";

import adminUsers from "./modules/administrator/users";
import adminCompany from "./modules/administrator/company";
import adminDepartments from "./modules/administrator/departments";
import adminExcel from "./modules/administrator/excel";
import adminPositions from "./modules/administrator/positions";
import adminRecipients from "./modules/administrator/recipients";
import adminProfiles from "./modules/administrator/profiles";
import adminProfileTasks from "./modules/administrator/profileTasks";
import adminAssessmentSessions from "./modules/administrator/sessions";
import superadminClients from "./modules/superadmin/clients";
import expertAssessment from "./modules/expert/expertAssessment";
import expertMaterials from "./modules/expert/expertMaterials";
import participantSessions from "./modules/participant/participantSessions";
import participantQuestionnaire from "./modules/participant/participantQuestionnaire";
import sharedSessions from "./modules/shared/sessions";
import sharedSimulation from "./modules/shared/simulation";
import sharedReports from "./modules/shared/reports";
import sharedReport from "./modules/shared/report";
import auth from "./modules/shared/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    adminUsers,
    adminCompany,
    adminDepartments,
    adminExcel,
    adminPositions,
    adminRecipients,
    adminProfiles,
    adminProfileTasks,
    adminAssessmentSessions,
    superadminClients,
    expertAssessment,
    expertMaterials,
    participantSessions,
    participantQuestionnaire,
    sharedSessions,
    sharedSimulation,
    sharedReport,
    sharedReports,
    auth,
  },
});
