<template>
  <v-btn
    text
    :class="['text-btn', { 'text-btn--small': small }]"
    @click="$emit('click')"
  >
    <v-icon
      v-if="!right"
      :style="{
        fontSize: size ? size : null,
      }"
      >{{ icon }}</v-icon
    >
    <slot></slot>
    <v-icon
      v-if="right"
      :style="{
        fontSize: size ? size : null,
      }"
      >{{ icon }}</v-icon
    >
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.text-btn {
  color: $grayColor !important;
  letter-spacing: 0.05rem;
  padding: 0 !important;
  min-width: 10px !important;
  .v-icon {
    color: $grayColor !important;
    font-size: 0.75rem;
  }
  &--small {
    font-size: 0.75rem !important;
    .v-icon {
      font-size: 0.75rem;
    }
  }
}
</style>
