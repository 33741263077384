<template>
  <v-dialog
    v-model="dialog"
    :persistent="persistent"
    @input="typeof extDialog === 'undefined' ? null : cancel()"
    class="elevation-3"
    max-width="350px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :block="block"
        :icon="icon"
        :text="textBtn"
        :disabled="btnDisabled"
        v-bind="attrs"
        v-on="on"
        :class="[
          { 'white--text primary': !icon && !textBtn },
          { 'btn-width': !icon && !block && !textBtn },
          { 'text-btn px-3': !icon && textBtn },
        ]"
        v-if="typeof extDialog === 'undefined'"
      >
        <slot></slot>
      </v-btn>
      <slot v-else></slot>
    </template>

    <v-card>
      <v-card-title class="justify-center text-center">
        <p class="form-header-text" v-html="text"></p>
      </v-card-title>
      <v-card-actions class="justify-space-around pa-5" style="flex-wrap: wrap">
        <div class="mb-2">
          <v-btn width="140px" class="white--text primary mx-1" @click="cancel">
            Нет
          </v-btn>
        </div>
        <div class="mb-2">
          <v-btn
            width="140px"
            class="white--text primary mx-1"
            @click="confirm"
          >
            Да
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: {
    extDialog: {
      type: Boolean,
      default: undefined,
    },
    text: {
      type: String,
      default: "Подтвердите действие",
    },
    block: {
      type: Boolean,
      default: false,
    },
    textBtn: {
      type: Boolean,
      default: false,
    },
    icon: Boolean,
    persistent: {
      type: Boolean,
      default: false,
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const dialog = ref(false);

    watch(
      () => _.extDialog,
      extDialog => {
        dialog.value = extDialog;
      }
    );

    const cancel = () => {
      emit("cancel");
      dialog.value = false;
    };

    const confirm = () => {
      emit("confirm");
      dialog.value = false;
    };

    return {
      dialog,
      cancel,
      confirm,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn-width {
  min-width: 220px !important;
}
</style>
