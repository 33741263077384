import axios from "@/axios";

export default {
  actions: {
    downloadExcel: (_, { target, role, ids }) =>
      axios
        .post(
          `/api/v1/admin/${target}/excel${
            role
              ? "?role=" +
                (role === "executive" ? "LEADER" : role.toUpperCase())
              : ""
          }`,
          { ids },
          {
            timeout: 180000,
            responseType: "blob",
            isNotShowToast: true,
          }
        )
        .then(data => {
          const link = window.URL.createObjectURL(data);
          const downloadLink = document.createElement("a");
          const options = {
            href: link,
            download: `${target}${Date.now()}.xls`,
            as: "fetch",
            type: "application/vnd.ms-excel",
          };
          for (let key of Object.keys(options))
            downloadLink.setAttribute(key, options[key]);
          downloadLink.click();
          downloadLink.remove();
        }),
  },
};
