<template>
  <div>
    <v-card
      class="pa-6 d-flex align-center"
      :style="{
        backgroundColor: '#e0e0e0',
        position: 'fixed',
        bottom: '0',
        zIndex: 100,
      }"
    >
      <p class="mr-6 text-center">
        Наш сайт использует файлы cookies и другие похожие технологии, чтобы
        предоставлять услуги, наиболее отвечающие вашим потребностям, а также
        накапливать статистическую информацию для анализа и улучшения наших
        услуг и работы сайта. Продолжая использовать наш сайт, вы соглашаетесь с
        условиями использования файлов cookies, в соответствии с
        <span class="tab-link" @click="switchToggle(true, 'policy')"
          >Политикой конфиденциальности.</span
        >
      </p>
      <v-btn class="white--text primary" @click="$emit('confirmed')"
        >Принять</v-btn
      >
    </v-card>
    <modal :dialog="toggle" :width="800">
      <PrivatePolicy v-show="toggle.view == 'policy'" />
    </modal>
  </div>
</template>

<script>
import { useModalToggle } from "@/use/helpers";
import PrivatePolicy from "@/components/policy/PrivatePolicy";

export default {
  setup() {
    const { toggle, switchToggle } = useModalToggle();

    return {
      toggle,
      switchToggle,
    };
  },
  components: {
    PrivatePolicy,
  },
};
</script>
