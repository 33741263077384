import axios from "@/axios";
import jwt_decode from "jwt-decode";

export default {
  state: {
    auth: null,
    roles: {
      PARTICIPANT: {
        role: "participant",
        title: "Участник оценки",
        pluralTitle: "Участники оценки",
        view: "participant",
        cases: ["участника", "участников"],
        hasExternal: true,
        assessmentPurpose: {
          HIRING: "Найм",
          PROMOTION: "Продвижение",
          DEVELOPMENT: "Развитие",
        },
      },
      EXPERT: {
        role: "expert",
        title: "Эксперт по оценке",
        pluralTitle: "Эксперты по оценке",
        view: "expert",
        cases: ["эксперта", "экспертов"],
        hasExternal: true,
      },
      EXECUTIVE: {
        role: "executive",
        title: "Руководитель",
        pluralTitle: "Руководители",
        view: "executive",
        cases: ["руководителя", "руководителей"],
        hasExternal: false,
      },
      OBSERVER: {
        role: "observer",
        title: "Наблюдатель",
        pluralTitle: "Наблюдатели",
        view: "observer",
        cases: ["наблюдателя", "наблюдателей"],
        hasExternal: true,
      },
      ADMIN: {
        role: "admin",
        title: "Администратор",
        pluralTitle: "Администраторы",
        view: "admin",
        cases: ["администратора", "администраторов"],
        hasExternal: true,
      },
      SUPER_ADMIN: {
        role: "super_admin",
        title: "Супер админ",
        view: "super_admin",
      },
    },
  },
  mutations: {
    login(state, decodedToken) {
      state.auth = decodedToken || null;
    },
    changeRole(state, data) {
      state.auth.roles.splice(state.auth.roles.indexOf(data.name), 1);

      state.auth.roles.unshift(data.name);

      if (!data.isSamePage) document.location.href = `/${data.view}`;
    },
    fillUserData: (state, data) => (state.auth = { ...data, ...state.auth }),
  },
  actions: {
    async login({ dispatch }, user = {}) {
      return await axios
        .post("/api/v1/login", JSON.stringify(user), { isNotShowToast: true })
        .then(res => {
          localStorage.setItem("token", res.token);
          dispatch("auth");
        });
    },
    logout() {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      document.location.href = "/auth";
    },
    async auth({ commit, dispatch }, data = localStorage.getItem("token")) {
      if (data) {
        const decoded = jwt_decode(data) || null;

        if (!decoded || decoded.exp - Math.round(Date.now() / 1000) <= 0) {
          dispatch("logout");
          return;
        } else {
          axios.defaults.headers.common["Authorization"] = `Bearer ${data}`;

          const index = decoded.roles.indexOf("LEADER");
          if (index !== -1) decoded.roles[index] = "EXECUTIVE";

          commit("login", decoded);
          dispatch("fetchCompanyLogo");
          dispatch("fillUserData");
        }
      }
    },
    fillUserData: ({ commit, getters }) =>
      axios
        .get("/api/v1/who_am_i?id=" + getters.getAuthData.id)
        .then(res => commit("fillUserData", res)),
  },
  getters: {
    getAuthData: state => state.auth,
    getCurrentRole: state => state.auth.roles[0].toLowerCase(),
    getRoles: state => state.roles,
    getRoleTitle: state => role =>
      state.roles[role === "LEADER" ? "EXECUTIVE" : role.toUpperCase()]?.title,
    getRoleList: state =>
      state.auth?.roles.map(el => ({
        name: el,
        ...state.roles[el],
      })),
    getFullName: state =>
      `${state.auth.lastName} ${state.auth.firstName}${
        state.auth.middleName ? " " + state.auth.middleName : ""
      }`,
  },
};
