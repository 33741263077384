import axios from "@/axios";

export default {
  state: {
    positions: [],
  },
  mutations: {
    setPositions(state, data) {
      state.positions = data;
    },
    pushPosition(state, data) {
      state.positions.unshift(data);
    },
    removePosition(state, id) {
      state.positions = state.positions.filter(el => el.id != id);
    },
    updatePosition(state, { id, name }) {
      state.positions.find(el => el.id == id).name = name;
    },
  },
  actions: {
    async fetchPositions({ commit, getters }) {
      if (getters.getPositions.length) return;

      await axios.get("/api/v1/admin/positions").then(res => {
        if (!res.error) {
          commit("setPositions", res.data);
        } else {
          return res.error;
        }
      });
    },
    async createPosition({ commit }, data) {
      return await axios
        .post(`/api/v1/admin/position`, data, {
          successText: "Должность создана",
        })
        .then(res => {
          if (!res.error) {
            commit("pushPosition", res);
          }
          return res.error;
        });
    },
    async removePosition({ commit }, id) {
      return await axios
        .delete("/api/v1/admin/position", {
          params: {
            id: id,
          },
          successText: "Должность удалена",
        })
        .then(res => {
          if (!res.error) {
            commit("removePosition", id);
          } else {
            return res.error;
          }
        });
    },
    async updatePosition({ commit }, data) {
      return await axios
        .put("/api/v1/admin/position", data.name, {
          params: {
            id: data.id,
          },
          successText: "Должность сохранена",
        })
        .then(res => {
          if (!res.error) {
            commit("updatePosition", data);
          }
          return res.error;
        });
    },
  },
  getters: {
    getPositions({ positions }) {
      return positions;
    },
  },
};
