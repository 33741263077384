<template>
  <div ref="infinityCollection">
    <slot />
  </div>
</template>

<script>
import { onMounted } from "@vue/composition-api";
import usePagination from "@/use/pagination";

export default {
  setup(_, { refs, emit }) {
    const { scrollHandler, getSelectMenu } = usePagination();

    onMounted(() => {
      const isTable =
        refs.infinityCollection.children[0].classList.contains("v-data-table");

      const isSelect =
        refs.infinityCollection.children[0].classList.contains("v-select");

      let container = refs.infinityCollection.children[0];

      if (isTable) {
        container = refs.infinityCollection.children[0].children[0];
      } else if (isSelect) {
        container = getSelectMenu(refs.infinityCollection.$el.children[0]);
      }

      scrollHandler(container, async () => {
        emit("scrolled");
      });
    });

    return {};
  },
};
</script>
