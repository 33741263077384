import axios from "@/axios";

export default {
  state: {
    company: {},
    companyLogoSrc: null,
  },
  mutations: {
    setCompany(state, data) {
      state.company = data;
    },
    uploadCompanyLogo(state, file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => (state.companyLogoSrc = fr.result);
    },
    setCompanyLogo: (state, logo) => (state.companyLogoSrc = logo),
    setDefaultCompanyLogo: state =>
      (state.companyLogoSrc = require("@/assets/img/logo.png")),
  },
  actions: {
    async fetchCompany({ commit, getters }) {
      const auth = getters.getAuthData;

      await axios
        .get(`/api/v1/admin/client?tenant_id=${auth.tenantId}`)
        .then(res => {
          if (!res.error) {
            commit("setCompany", res);
          } else {
            return res.error;
          }
        });
    },
    async uploadCompanyLogo({ commit }, file) {
      let formData = new FormData();
      formData.append("file", file);

      return await axios
        .post("/api/v1/admin/client/logo", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          successText: "Логотип изменен",
        })
        .then(res => {
          if (!res.error) {
            commit("uploadCompanyLogo", file);
          }
          return res.error;
        });
    },
    async removeCompanyLogo({ commit }) {
      return await axios
        .delete("/api/v1/admin/client/logo-reset", {
          successText: "Логотип удален",
        })
        .then(res => {
          if (!res.error) {
            commit("setDefaultCompanyLogo");
          }
          return res.error;
        });
    },
    fetchCompanyLogo: ({ commit }) =>
      axios
        .get("/api/v1/files/logo", { responseType: "blob" })
        .then(res => {
          if (res.size)
            commit("setCompanyLogo", window.URL.createObjectURL(res));
          else commit("setDefaultCompanyLogo");
        })
        .catch(() => commit("setDefaultCompanyLogo")),
  },
  getters: {
    getCompany({ company }) {
      return {
        formattedDate: new Date(company.createdDate).toLocaleDateString(
          "ru-RU",
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
        ),
        ...company,
      };
    },
    getCompanyLogo({ companyLogoSrc }) {
      return companyLogoSrc;
    },
  },
};
