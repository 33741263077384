import Vue from "vue";
import "@/composition";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.min.css";
import axios from "./axios";
import store from "./store";
import "./styles/main.scss";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import GlobalComponents from "@/components/global";

Object.entries(GlobalComponents).forEach(([name, component]) =>
  Vue.component(name, component)
);

Vue.config.productionTip = false;
Vue.use(VueToast);

store.dispatch("auth");

new Vue({
  axios,
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount("#app");
