import axios from "@/axios";

export default {
  state: {
    currentReport: null,
    initialReport: {},
    reportQuestionnaire: {},
    reportAnswers: {},
  },
  mutations: {
    setCurrentReport(state, data) {
      data.competencesResults
        .sort((a, b) => a.indexNumber - b.indexNumber)
        .forEach(
          el =>
            (el.indicators = el.indicatorResults.sort(
              (a, b) => a.indexNumber - b.indexNumber
            ))
        );

      data.competencesShortResults.sort(
        (a, b) => a.indexNumber - b.indexNumber
      );

      if (!data.updateMode) state.currentReport = data;
      else
        for (let key of Object.keys(data)) state.currentReport[key] = data[key];
      state.initialReport = { ...data };
    },
    setReportQuestionnaire(state, questionnaire) {
      questionnaire.questions.sort((a, b) => a.indexNumber - b.indexNumber);
      state.reportQuestionnaire = questionnaire;
    },
    setReportAnswers(state, answers) {
      state.reportAnswers = answers;
    },
    clearCurrentReport(state) {
      state.currentReport = null;
      state.initialReport = {};
      state.reportQuestionnaire = {};
      state.reportAnswers = {};
    },
    resetCurrentReport(state, reverse = false) {
      if (reverse) {
        state.initialReport = { ...state.currentReport };
      } else {
        state.currentReport = { ...state.initialReport };
      }
    },
    updateReportIndicator(state, { competenceId, indicatorId, data }) {
      const indicator = state.currentReport.competencesResults
        .find(el => el.id == competenceId)
        .indicatorResults.find(el => el.id == indicatorId);

      Object.keys(data).forEach(key => {
        indicator[key] = data[key];
      });

      state.initialReport = JSON.parse(JSON.stringify(state.currentReport));
    },
    updateReportCompetence(state, { competenceId, data }) {
      const index = state.currentReport.competencesResults.findIndex(
        el => el.id == competenceId
      );
      state.currentReport.competencesResults[index] = Object.assign(
        {},
        state.currentReport.competencesResults[index],
        data
      );
      state.initialReport = { ...state.currentReport };
    },
    updateReportGeneral(
      state,
      {
        strengths,
        improvementZones,
        improvementRecommendations,
        additionalComment,
      }
    ) {
      state.currentReport.generalStrengths = strengths;
      state.currentReport.generalImprovementZones = improvementZones;
      state.currentReport.generalImprovementRecommendations =
        improvementRecommendations;
      state.currentReport.generalAdditionalComment = additionalComment;
    },
    updateReportStatus(state, status) {
      state.currentReport.sessionStatus = status;
    },
    updateReportVisibility(state, { competenceId, role, settings }) {
      if (competenceId) {
        const competenceIndex =
          state.currentReport.competencesResults.findIndex(
            el => el.id == competenceId
          );
        const roleIndex = state.currentReport.competencesResults[
          competenceIndex
        ].competenceVisibilityByRole.findIndex(
          el => el.role == role.toUpperCase()
        );
        state.currentReport.competencesResults[
          competenceIndex
        ].competenceVisibilityByRole[roleIndex] = settings;
      } else {
        const index = state.currentReport.reportVisibilityByRole.findIndex(
          el => el.role == role.toUpperCase()
        );
        state.currentReport.reportVisibilityByRole[index] = settings;
      }
    },
  },
  actions: {
    async fetchCurrentReport(
      { commit },
      { sessionId, role, updateMode = false }
    ) {
      await axios
        .get(
          `/api/v1/${
            role === "executive" ? "leader" : role
          }/report?sessionId=${sessionId}`
        )
        .then(res => {
          res.sessionId = sessionId;
          res.updateMode = updateMode;
          commit("setCurrentReport", res);
        });
    },

    async fetchReportQuestionnaire({ commit }, sessionId) {
      await axios
        .get(`/api/v1/report/questionnaire?sessionId=${sessionId}`, {
          isIgnoreErrors: true,
        })
        .then(res => {
          if (!res.error) {
            commit("setReportQuestionnaire", res);
          }
          return res.error;
        });
    },

    async fetchReportAnswers({ commit }, sessionId) {
      await axios
        .get(`/api/v1/report/answers?sessionId=${sessionId}`)
        .then(res => {
          if (!res.error) {
            commit("setReportAnswers", res);
          }
        });
    },

    async updateReportIndicator(
      { commit },
      { sessionId, competenceId, indicatorId, data }
    ) {
      return await axios
        .post(
          `/api/v1/expert/report/indicator?sessionId=${sessionId}&competenceId=${competenceId}&indicatorId=${indicatorId}`,
          JSON.stringify(data),
          { successText: "Выводы по индикатору сохранены" }
        )
        .then(res => {
          if (!res.error) {
            commit("updateReportIndicator", {
              competenceId,
              indicatorId,
              data,
            });
          }
          return res.error;
        });
    },

    async updateReportCompetence(
      { commit },
      { sessionId, competenceId, data }
    ) {
      return await axios
        .post(
          `/api/v1/expert/report/competence?sessionId=${sessionId}&competenceId=${competenceId}`,
          JSON.stringify(data),
          { successText: "Выводы по компетенции сохранены" }
        )
        .then(res => {
          if (!res.error) {
            commit("updateReportCompetence", {
              competenceId,
              data,
            });
          }
          return res.error;
        });
    },

    async updateReportGeneral({ commit }, { sessionId, data }) {
      return await axios
        .post(
          `/api/v1/expert/report/general?sessionId=${sessionId}`,
          JSON.stringify(data),
          { successText: "Отчет сохранен" }
        )
        .then(res => {
          if (!res.error) {
            commit("updateReportGeneral", data);
          }
          return res.error;
        });
    },

    async finishReport({ commit }, sessionId) {
      return await axios
        .post(
          `/api/v1/expert/report/finish?sessionId=${sessionId}`,
          {},
          { successText: "Отчет завершен" }
        )
        .then(res => {
          if (!res.error) commit("updateReportStatus", "REPORT_CREATED");
          return res.error;
        });
    },

    publishReport: ({ state, getters }, { sessionId, role }) =>
      axios.post(
        "/api/v1/expert/report/publish",
        {
          sessionId: sessionId,
          role: role === "executive" ? "LEADER" : role.toUpperCase(),
          reportVisibility: getters.getReportVisibility(role),
          competencesVisibility: state.currentReport.competencesResults.map(
            el => getters.getCompetenceVisibility(role, el.id)
          ),
        },
        {
          successText: "Отчет для текущей роли опубликован",
        }
      ),

    downloadReport: async ({ getters }) =>
      await axios
        .get(
          `/api/v1/${
            getters.getCurrentRole === "executive"
              ? "leader"
              : getters.getCurrentRole
          }/report/pdf?sessionId=${getters.getCurrentReport.sessionId}`,
          {
            timeout: 180000,
            responseType: "blob",
            isNotShowToast: true,
          }
        )
        .then(data => {
          const link = window.URL.createObjectURL(data);
          const downloadLink = document.createElement("a");
          const options = {
            href: link,
            download: "report.pdf",
            as: "fetch",
            type: "application/vnd.ms-excel",
          };
          for (let key of Object.keys(options))
            downloadLink.setAttribute(key, options[key]);
          downloadLink.click();
          downloadLink.remove();
        }),
  },
  getters: {
    getCurrentReport: state => state.currentReport,

    getReportQuestionnaire: state => state.reportQuestionnaire,

    getReportAnswers: state => state.reportAnswers,

    getSessionId: state => state.currentReport.sessionId,

    isReportCompleted: state =>
      ["REPORT_CREATED", "REPORT_PUBLISHED"].indexOf(
        state.currentReport.sessionStatus
      ) !== -1,

    getReportSettings: state => role =>
      state.currentReport.reportSettings.find(el =>
        role === "executive"
          ? el.role === "LEADER"
          : el.role === role.toUpperCase()
      ) || {},

    getReportVisibility:
      ({ currentReport }) =>
      role =>
        currentReport.reportVisibilityByRole.find(el =>
          role === "executive"
            ? el.role === "LEADER"
            : el.role === role.toUpperCase()
        ) || {},

    getCompetenceVisibility:
      ({ currentReport }) =>
      (role, competenceId) =>
        currentReport.competencesResults
          .find(el => el.id == competenceId)
          .competenceVisibilityByRole.find(el =>
            role === "executive"
              ? el.role === "LEADER"
              : el.role === role.toUpperCase()
          ) || {},

    isReportItemVisible:
      ({ currentReport }, { getCurrentRole }) =>
      setting =>
        currentReport.reportVisibilityByRole.find(el =>
          getCurrentRole === "executive"
            ? el.role === "LEADER"
            : el.role === getCurrentRole.toUpperCase()
        )[setting],

    isCompetenceItemVisible:
      ({ currentReport }, { getCurrentRole }) =>
      (competenceId, setting) =>
        currentReport.competencesResults
          .find(el => el.id == competenceId)
          .competenceVisibilityByRole.find(el =>
            getCurrentRole === "executive"
              ? el.role === "LEADER"
              : el.role === getCurrentRole.toUpperCase()
          )[setting],

    getReportElement:
      ({ currentReport }) =>
      (element, competenceId) => {
        if (!competenceId) {
          return currentReport[
            `general${element.substring(0, 1).toUpperCase()}${element.substring(
              1
            )}`
          ];
        } else
          return currentReport.competencesResults.find(
            el => el.id == competenceId
          )[element];
      },

    getCompetence:
      ({ currentReport }) =>
      competenceId =>
        currentReport.competencesResults.find(el => el.id == competenceId),
  },
};
