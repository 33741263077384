import axios from "@/axios";
import { useFormattedDates } from "@/use/helpers";
import { useSessionStatus } from "@/use/sessions";
import usePagination from "@/use/pagination";
const { getLocaleStatus } = useSessionStatus();

const { getTimeZone } = useFormattedDates();

const {
  incrementElement,
  decrementElement,
  pagebleQuery,
  updateModel,
  clearModel,
} = usePagination();

export default {
  state: {
    assessmentSessions: {
      content: [],
    },
    currentAssessmentSession: {},
  },
  mutations: {
    setAssessmentSessions(state, sessions) {
      state.assessmentSessions = updateModel(
        state.assessmentSessions,
        sessions
      );
    },
    clearAssessmentSessions(state) {
      state.assessmentSessions = clearModel(state.assessmentSessions);
    },
    setCurrentAssessmentSessions(state, session) {
      state.currentAssessmentSession = session;
    },
    pushAssessmentSession(state, session) {
      state.assessmentSessions.content.unshift(session);
      incrementElement(state.assessmentSessions);
    },
    updateAssessmentSession(state, session) {
      const index = state.assessmentSessions.content.findIndex(
        el => el.id == session.id
      );

      state.assessmentSessions.content.splice(index, 1, session);
      state.assessmentSessions = JSON.parse(
        JSON.stringify(state.assessmentSessions)
      );
    },
    removeAssessmentSession(state, ids) {
      state.assessmentSessions.content =
        state.assessmentSessions.content.filter(el => !ids.includes(el.id));

      decrementElement(state.assessmentSessions);
    },
  },
  actions: {
    async fetchAssessmentSessions({ commit, state }) {
      if (state.assessmentSessions.last) return;

      await axios
        .get(
          `/api/v1/assessment_sessions?role=ADMIN&${pagebleQuery(
            state.assessmentSessions
          )}`
        )
        .then(res => {
          if (!res.error) {
            commit("setAssessmentSessions", res);
          } else {
            return res.error;
          }
        });
    },

    async fetchAssessmentSessionById({ commit }, id) {
      await axios.get("/api/v1/admin/assessment_session?id=" + id).then(res => {
        if (!res.error) {
          commit("setCurrentAssessmentSession", res);
        } else {
          return res.error;
        }
      });
    },

    async createAssessmentSession({ commit }, session) {
      session.startTimestamp = `${session.date}T${session.time}:00${getTimeZone(
        session.date,
        session.time
      )}`;

      session.notifyParticipantTimestamp = `${session.notifyDate}T${
        session.notifyTime
      }:00${getTimeZone(session.notifyDate, session.notifyTime)}`;

      if (!session.expertsList) session.expertsList = [];
      if (!session.leadersList) session.leadersList = [];
      if (!session.observersList) session.observersList = [];

      await axios
        .get(
          `/api/v1/who_am_i?id=${
            session.participantUserId || session.participantUser.id
          }`
        )
        .then(res => {
          if (!res.error) {
            session.assessmentPurpose = res.assessmentPurpose;
          }
        });

      const updatedSession = {
        startTimestamp: session.startTimestamp,
        endTimestamp: session.endTimestamp,
        notifyParticipantTimestamp: session.notifyParticipantTimestamp,
        participantUserId:
          session.participantUserId || session.participantUser.id,
        expertIdList: session.expertsList.map(el => el.id),
        leaderIdList: session.leadersList.map(el => el.id),
        observerIdList: session.observersList.map(el => el.id),
        mainExpertId: session.mainExpertId,
      };

      return await axios
        .post(
          "/api/v1/admin/assessment_session",
          JSON.stringify(updatedSession),
          { successText: "Оценочная сессия добавлена" }
        )
        .then(res => {
          if (!res.error) {
            session.id = res.id;
            session.status = "PENDING";
            session.endTimestamp = res.endTimestamp;
            session.participantUser = res.participantUser;

            commit("pushAssessmentSession", session);
            commit("removeUsers", [
              "participantsShort",
              [session.participantUser.id],
            ]);
          }
          return res.error;
        });
    },

    async updateAssessmentSession({ commit }, session) {
      session.startTimestamp = `${session.date}T${session.time}:00${getTimeZone(
        session.date,
        session.time
      )}`;

      if (session.endTimestamp.length == 19) {
        const [endDate] = session.endTimestamp.slice(0, -3).split("T");
        session.endTimestamp = `${endDate}T${
          session.assessmentSessionEndTime
        }:00${getTimeZone(endDate, session.assessmentSessionEndTime)}`;
      }

      if (!session.expertsList) session.expertsList = [];
      if (!session.leadersList) session.leadersList = [];
      if (!session.observersList) session.observersList = [];

      session.notifyParticipantTimestamp = `${session.notifyDate}T${
        session.notifyTime
      }:00${getTimeZone(session.notifyDate, session.notifyTime)}`;

      await axios
        .get(
          `/api/v1/who_am_i?id=${
            session.participantUserId || session.participantUser.id
          }`
        )
        .then(res => {
          if (!res.error) {
            session.assessmentPurpose = res.assessmentPurpose;
          }
        });

      const updatedSession = {
        startTimestamp: session.startTimestamp,
        endTimestamp: session.endTimestamp,
        notifyParticipantTimestamp: session.notifyParticipantTimestamp,
        participantUserId: session.participantUser.id,
        expertIdList: session.expertsList.map(el => el.id),
        leaderIdList: session.leadersList.map(el => el.id),
        observerIdList: session.observersList.map(el => el.id),
        mainExpertId: session.mainExpertId,
        durationMinutes: 300,
      };

      return await axios
        .post(
          "/api/v1/admin/assessment_session?sessionId=" + session.id,
          JSON.stringify(updatedSession),
          { successText: "Оценочная сессия обновлена" }
        )
        .then(res => {
          if (!res.error) {
            commit("updateAssessmentSession", session);
          }
          return res.error;
        });
    },

    async removeAssessmentSessions({ commit }, ids) {
      return await axios
        .delete("/api/v1/admin/assessment_session", {
          data: {
            ids,
          },
          successText: "Оценочные сессии удалены",
        })
        .then(res => {
          if (!res.error) {
            commit("removeAssessmentSession", ids);
          } else {
            return res.error;
          }
        });
    },
  },
  getters: {
    getAssessmentSessions(state, getters) {
      const sessions = JSON.parse(JSON.stringify(state.assessmentSessions));
      if (!sessions.content) return {};

      sessions.content.map(el => {
        el.localeAssessmentPurpose =
          getters.getRoles.PARTICIPANT.assessmentPurpose[
            el.assessmentPurpose
          ] || el.assessmentPurpose;

        el.assessmentSessionDate = new Date(el.startTimestamp).toLocaleString(
          "ru-RU",
          {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
          }
        );
        el.assessmentSessionStartTime = new Date(
          el.startTimestamp
        ).toLocaleString("ru-RU", {
          hour: "2-digit",
          minute: "2-digit",
        });

        el.assessmentSessionEndTime = new Date(el.endTimestamp).toLocaleString(
          "ru-RU",
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        );

        const [notifyDate, notifyTime] =
          el.notifyParticipantTimestamp.split("T");
        el.notifyDate = notifyDate;
        el.notifyTime = notifyTime.slice(0, 5);

        el.assessmentSessionTime = `${el.assessmentSessionStartTime} - ${el.assessmentSessionEndTime}`;

        el.status = getLocaleStatus(el.status) || el.status;

        const { lastName, firstName, middleName } = el.participantUser;
        el.fullName = `${lastName} ${firstName} ${middleName}`
          .trim()
          .replace(/-/g, "");

        el.participantUserId = el.participantUser.id;

        Object.keys(el.participantUser).forEach(key => {
          el.participantUser[key] = el.participantUser[key]
            ? el.participantUser[key]
            : "-";
        });

        return el;
      });
      return sessions;
    },
    getCurrentAssessmentSessions({ currentAssessmentSession }) {
      return currentAssessmentSession || {};
    },
    isNeedInitialLoad: state => !state.assessmentSessions.content.length,
  },
};
