import axios from "@/axios";

export default {
  state: {
    recipients: [],
  },
  mutations: {
    setRecipients: (state, data) =>
      (state.recipients = data.map((el, idx) => ({
        id: idx + 1,
        name: el,
      }))),
    pushRecipient: (state, data) =>
      state.recipients.push({
        id: Date.now(),
        name: data,
      }),
    removeRecipient: (state, id) =>
      (state.recipients = state.recipients.filter(el => el.id != id)),
    updateRecipient: (state, { id, name }) =>
      (state.recipients.find(el => el.id == id).name = name),
  },
  actions: {
    fetchRecipients: ({ commit }, profileId) =>
      axios
        .get("/api/v1/admin/simulation/recipients?profileId=" + profileId)
        .then(res => commit("setRecipients", res.data)),
    createRecipient: ({ commit, getters }, name) =>
      axios
        .put(
          "/api/v1/admin/simulation/recipients",
          {
            profileId: getters.getCurrentProfile.id,
            recipients: [...getters.getRecipientsNames, name],
          },
          { successText: "Адресат добавлен" }
        )
        .then(() => commit("pushRecipient", name)),
    updateRecipient: ({ commit, getters, dispatch }, recipient) =>
      axios
        .put(
          "/api/v1/admin/simulation/recipients",
          {
            profileId: getters.getCurrentProfile.id,
            recipients: getters.getRecipientsNames,
            recipientEdited: {
              previous: getters.getRecipients.find(el => el.id === recipient.id)
                .name,
              new: recipient.name,
            },
          },
          { successText: "Адресат изменен" }
        )
        .then(res => {
          commit("setRecipients", res.data);
          dispatch("updateProfileEmails");
          dispatch("updateProfileEvents");
        }),
    removeRecipient: ({ commit, getters, dispatch }, id) =>
      axios
        .put(
          "/api/v1/admin/simulation/recipients",
          {
            profileId: getters.getCurrentProfile.id,
            recipients: getters.getRecipients
              .filter(el => el.id !== id)
              .map(el => el.name),
          },
          { successText: "Адресат удален" }
        )
        .then(() => {
          commit("removeRecipient", id);
          dispatch("updateProfileEmails");
          dispatch("updateProfileEvents");
        }),
  },
  getters: {
    getRecipients: state => state.recipients,
    getRecipientsNames: state => state.recipients.map(el => el.name),
  },
};
