<template>
  <div class="pt-2 font-weight-regular" style="font-size: 15px">
    <text-btn
      v-if="role === 'participant'"
      icon="mdi-arrow-left"
      class="mb-5"
      @click="$emit('tab-selected', 'Main')"
    >
      На главную
    </text-btn>
    <p class="mb-3">
      <b class="mr-1">Фамилия, имя и отчество:</b>
      {{ currentUser.lastName }}
      {{ currentUser.firstName }}
      {{ currentUser.middleName }}
    </p>
    <p class="mb-3">
      <b class="mr-1">Компания:</b>{{ currentUser.companyName || "-" }}
    </p>
    <p class="mb-3">
      <b class="mr-1">Подразделение:</b
      >{{ currentUser.currentDepartment || "-" }}
    </p>
    <p class="mb-3">
      <b class="mr-1">Должность:</b>{{ currentUser.currentPosition || "-" }}
    </p>
    <p class="mb-3">
      <b class="mr-1">Телефон:</b>{{ currentUser.phone || "-" }}
    </p>
    <p class="mb-8">
      <b class="mr-1">Эл. почта:</b>
      <a
        style="color: #4c5ca7; text-decoration: underline"
        :href="`mailto:${currentUser.email}`"
        >{{ currentUser.email || "-" }}</a
      >
    </p>
    <p style="line-height: 2rem">
      При появлении вопросов или при наличии ошибки Вы можете связаться с Вашим,
      <br />
      администратором по почте:
      <a
        style="color: #4c5ca7; text-decoration: underline"
        :href="`mailto:${currentUser.invitedByEmail || '#'}`"
        >{{ currentUser.invitedByEmail || "-" }}</a
      >
    </p>
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const currentUser = computed(() => root.$store.getters.getCurrentUser);
    const authData = computed(() => root.$store.getters.getAuthData);

    const role = root.$store.getters.getCurrentRole;

    onBeforeMount(async () => {
      await root.$store.dispatch("fetchUserByIdAllRoles", authData.value.id);
    });

    onMounted(() => localStorage.setItem("lastTab", "MyData"));

    return {
      currentUser,
      authData,
      role,
    };
  },
};
</script>
