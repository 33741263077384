import axios from "@/axios";

export default {
  state: {
    expertMaterials: [],
  },
  mutations: {
    setExpertMaterials(state, data) {
      state.expertMaterials = data;
    },
  },
  actions: {
    async fetchExpertMaterials({ commit }) {
      return await axios.get("/api/v1/expert/materials").then(res => {
        commit("setExpertMaterials", res.profileMaterials);
        return res.error;
      });
    },
  },
  getters: {
    getExpertMaterials({ expertMaterials }) {
      return expertMaterials;
    },
  },
};
