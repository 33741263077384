<template>
  <v-expansion-panels accordion :multiple="multiple" v-model="panelsIndexes">
    <v-expansion-panel
      v-for="(item, i) in items"
      :key="i"
      style="min-width: 100%"
    >
      <v-expansion-panel-header @click="$emit('clickOnHeader', i)">
        <slot name="header" :item="item"></slot>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list class="py-0">
          <slot name="content" :item="item"></slot>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";

export default {
  props: {
    items: Array,
    collapsed: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    firstSelected: {
      type: Boolean,
      default: false,
    },
    additionalEspansion: {
      type: Number,
      default: -1,
    },
  },
  setup(_, { emit }) {
    const panelsIndexes = _.multiple
      ? ref(_.collapsed ? (_.firstSelected ? [0] : []) : [...Array(100).keys()])
      : computed({
          get: () => _.additionalEspansion,
          set: () => true,
        });

    if (_.multiple)
      watch(
        () => _.additionalEspansion,
        () =>
          (panelsIndexes.value = [
            ...panelsIndexes.value,
            _.additionalEspansion,
          ])
      );

    watch(panelsIndexes, val => {
      emit("selected", _.items[val]);
    });

    return {
      panelsIndexes,
    };
  },
};
</script>

<style lang="scss"></style>
