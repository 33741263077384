<template>
  <nav class="flex-shrink-0">
    <ul class="d-flex nav">
      <li
        v-for="tab in tabs"
        :key="tab.name"
        :class="[
          'nav-item',
          additionalItemClass,
          {
            active: tab.name == currentTab,
          },
        ]"
        @click="selectTab(tab)"
      >
        <span class="nav-link">{{ tab.label }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    tabs: Array,
    currentTab: String,
    additionalItemClass: {
      type: String,
      default: "thisClassWillNeverUsed",
    },
  },
  setup(_, { emit }) {
    const selectTab = tab => {
      emit("tab-selected", tab.name);
    };

    return {
      selectTab,
    };
  },
};
</script>
