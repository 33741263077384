var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[(_vm.cases.title)?_c('p',{staticClass:"subtitle mb-1 primary--text",style:({
      position: 'relative',
      paddingRight: '18px',
    })},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.cases.title))]}),_vm._v(": "),(_vm.help)?_c('v-hover',{style:({
        position: 'absolute',
        top: '0',
        right: '0',
      }),scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('div',[_c('v-icon',{staticClass:"text-center",attrs:{"color":"#767676","small":""}},[_vm._v("mdi-help-circle ")]),_c('div',{staticStyle:{"position":"relative"}},[(hover)?_c('div',{staticClass:"elevation-3 pa-3 white text--secondary",staticStyle:{"position":"absolute","z-index":"100","overflow-y":"auto","max-height":"200px","width":"300px","right":"0"}},[_vm._v(" "+_vm._s(_vm.help)+" ")]):_vm._e()])],1)]}}],null,false,3589024930)}):_vm._e()],2):_vm._e(),(_vm.data.length)?_c('v-card',{staticClass:"mb-1",style:({
      maxHeight: _vm.height,
      overflowY: 'auto',
    }),attrs:{"elevation":"3"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.data),function(item,idx){return _c('v-list-item',{key:idx,staticClass:"brd-b"},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"max-width":"100%"}},[(!_vm.links)?_c('p',{style:({
                maxWidth: _vm.edit || _vm.remove ? 'calc(100% - 80px)' : '100%',
                lineHeight: '20px',
              }),domProps:{"innerHTML":_vm._s(_vm.stringArray ? item : item[_vm.itemTitle])}}):_c('p',{style:({
                maxWidth: _vm.edit || _vm.remove ? 'calc(100% - 80px)' : '100%',
                lineHeight: '20px',
              })},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item[_vm.itemTitle]))])]),_c('div',{staticClass:"flex-shrink-0"},[(_vm.edit)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit', item)}}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"#767676"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.remove)?_c('confirm',{attrs:{"icon":"","text":_vm.cases.remove || 'Подтвердите удаление'},on:{"confirm":function($event){return _vm.$emit('remove', item)}}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"#767676"}},[_vm._v("mdi-delete")])],1):_vm._e()],1)])])],1)}),1)],1):_vm._e(),(_vm.add)?_c('text-btn',{attrs:{"icon":"mdi-plus"},on:{"click":function($event){return _vm.$emit('add')}}},[_vm._v(_vm._s(_vm.cases.add))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }