import axios from "@/axios";

export default {
  state: {
    profileTasks: [],
    currentProfileTask: {},
  },
  mutations: {
    setProfileTasks(state, tasks) {
      state.profileTasks = tasks;
    },
    setCurrentProfileTask(state, task) {
      state.currentProfileTask = task;
    },
    createProfileTask(state, task) {
      state.profileTasks.push({
        id: task.id,
        name: task.name,
      });

      state.currentProfileTask = task;
    },
    updateProfileTask(state, task) {
      if (task.withTabSwitch) return;
      const profileTask = state.profileTasks.find(el => el.id == task.id);
      profileTask.name = task.name;

      state.currentProfileTask = task;
    },
    removeProfileTask(state, id) {
      const index = state.profileTasks.findIndex(el => el.id == id);
      state.profileTasks.splice(index, 1);
    },
    clearProfileTasks(state) {
      state.profileTasks = [];
      state.currentProfileTask = {};
    },
  },
  actions: {
    async fetchProfileTasks({ commit }, profileId) {
      await axios
        .get("/api/v1/admin/profile_tasks?profileId=" + profileId)
        .then(res => {
          if (!res.error) {
            res.data.sort(
              (a, b) => new Date(a.createTs) - new Date(b.createTs)
            );
            commit("setProfileTasks", res.data);
          } else {
            return res.error;
          }
        });
    },

    async fetchCurrentProfileTask({ commit }, profileTaskId) {
      await axios
        .get("/api/v1/admin/profile_task?profileTaskId=" + profileTaskId)
        .then(res => {
          if (!res.error) {
            commit("setCurrentProfileTask", res);
          } else {
            return res.error;
          }
        });
    },

    async createOrUpdateProfileTask(
      { commit },
      { profileId, task, successText = "Задание сохранено" }
    ) {
      const editedTask = JSON.parse(JSON.stringify(task));

      if (task.id) {
        editedTask.profileTaskId = task.id;
        delete editedTask.id;
      }

      return await axios
        .post(`/api/v1/admin/profile_task`, JSON.stringify(editedTask), {
          params: {
            profileId,
          },
          successText: successText,
        })
        .then(res => {
          if (!res.error) {
            if (task.id) {
              commit("updateProfileTask", task);
            } else {
              task.id = res.id;
              commit("createProfileTask", task);
            }
          }
          return res.error;
        });
    },

    async removeProfileTask({ commit }, profileTaskId) {
      return await axios
        .delete("/api/v1/admin/profile_task", {
          params: {
            profileTaskId,
          },
          successText: "Задание удалено",
        })
        .then(res => {
          if (!res.error) {
            commit("removeProfileTask", profileTaskId);
          } else {
            return res.error;
          }
        });
    },
  },
  getters: {
    getProfileTasks({ profileTasks }) {
      return profileTasks;
    },
    getCurrentProfileTask({ currentProfileTask }) {
      return currentProfileTask;
    },
  },
};
