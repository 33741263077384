import axios from "@/axios";
import { useSessionStatus } from "@/use/sessions";
import usePagination from "@/use/pagination";

const { getLocaleStatus } = useSessionStatus();

const { pagebleQuery, updateModel } = usePagination();

export default {
  state: {
    reports: {
      content: [],
    },
  },
  mutations: {
    setReports(state, reports) {
      state.reports = updateModel(state.reports, reports);
    },
    clearReports(state) {
      state.reports = {
        content: [],
      };
    },
    setReportVideoLink(state, data) {
      state.reports.content.find(
        el => el.sessionId === data.sessionId
      ).videoRecordLink = data.link;
    },
  },
  actions: {
    fetchReports: ({ commit, getters }) => {
      if (getters.isLastReport) return;

      const role = getters.getCurrentRole;

      axios
        .get(
          `/api/v1/${
            role === "executive" ? "leader" : role
          }/reports?${pagebleQuery(getters.getReportsModel)}`
        )
        .then(res => commit("setReports", res));
    },
    setReportVideoLink: ({ commit }, data) =>
      axios
        .post(
          "/api/v1/expert/session/video?sessionId=" + data.sessionId,
          data.link,
          { successText: "Видеозапись сохранена" }
        )
        .then(() => commit("setReportVideoLink", data)),
  },
  getters: {
    getReports({ reports }, getters) {
      reports.content.forEach(el => {
        el.assessmentSessionDate = new Date(
          el.assessmentTimestamp
        ).toLocaleString("ru-RU", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        });
        el.id = el.sessionId;

        el.localeAssessmentPurpose =
          getters.getRoles.PARTICIPANT.assessmentPurpose[
            el.assessmentPurpose
          ] || el.assessmentPurpose;

        el.localeStatus = getLocaleStatus(el.status) || el.status;

        Object.keys(el).forEach(key => {
          if (key == "videoRecordLink") return;
          el[key] = el[key] ? el[key] : "-";
        });

        el.prior = Number(new Date(el.assessmentTimestamp));
      });

      return reports;
    },
    isLastReport: state => state.reports.last,
    getReportsModel: state => state.reports,
  },
};
