var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preloader-global",style:({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
  })},[_c('v-progress-circular',{attrs:{"size":80,"width":5,"color":"primary","indeterminate":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }