<template>
  <v-dialog
    v-model="dialog.isOpen"
    :persistent="persistent"
    class="elevation-3"
    :max-width="width || null"
  >
    <div style="position: relative">
      <div
        class="pa-3"
        style="
          background: rgba(255, 255, 255, 0.9);
          position: absolute;
          right: 0;
          top: 0;
          z-index: 20;
        "
      >
        <v-icon class="black--text" @click="dialog.isOpen = false">
          mdi-close
        </v-icon>
      </div>
      <v-card
        class="pa-6"
        :style="{
          maxHeight: '90vh',
          overflowY: 'auto',
        }"
      >
        <slot></slot>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { watch } from "@vue/composition-api";

export default {
  props: {
    dialog: Object,
    width: {
      type: Number,
      default: 450,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  setup(_) {
    let timeout;
    watch(_.dialog, val => {
      if (!val.isOpen) {
        timeout = setTimeout(() => (val.view = ""), 300);
      } else {
        clearTimeout(timeout);
      }
    });
  },
};
</script>
